.steps-carousel {
    transition: transform 0.3s ease-in-out, margin-bottom 0.3s ease-in-out;    
}
   
    .container {
        overflow: hidden;
        padding-left: 25px;
        padding-right: 25px;
        max-width: 1630px;
        margin: 0 auto;
    }

    .steps-carousel-view {
        transform: translateY(0);
        -webkit-transform: translateY(0);
        margin-bottom: 20px;
    }

    .slick-list {
        padding-top: 10px;
    }

    .slick-arrow {
        margin-top: 0;
    }

    .slick-prev {
        margin-left: -15px;
    }

    .slick-next {
        margin-right: -15px;
    }
    
    .slick-slider {
        margin: 0 auto 20px;
    }
    
    .slick-track {
        @media (min-width: 820px) {
            min-height: 150px;
        }
    }
    
    .slick-track .slick-slide:first-child {
        background: linear-gradient(to right,  rgba(255,255,255,1) 50%,rgba(255,255,255,0)), linear-gradient(-180deg, 
            rgba(0,0,0,0) calc(55% - 1px), 
            rgba(0,0,0,1) calc(55%), 
            rgba(0,0,0,0) calc(55% + 1px)
        ) !important;
        margin-left:100px,

    }
    .slick-track > .slick-slide:last-child {
        background: linear-gradient(to left,  rgba(255,255,255,1) 50%,rgba(255,255,255,0)), linear-gradient(-180deg, 
            rgba(0,0,0,0) calc(55% - 1px), 
            rgba(0,0,0,1) calc(55%), 
            rgba(0,0,0,0) calc(55% + 1px)
        ) !important;

    }
    
    .slick-slider {
        margin: 0 auto 20px;
    }

    .slick-slide {   
        padding-left: 25px;
        padding-right: 25px;             
        height: inherit !important;   
        width: 300px;     
        background: linear-gradient(180deg, 
            rgba(0,0,0,0) calc(55% - 1px), 
            rgba(0,0,0,1) calc(55%), 
            rgba(0,0,0,0) calc(55% + 1px)
        );
        @media (min-width: 820px) {
            width: 340px !important;
        }
    }

    .slick-slide img {
        display: inline;
    }

    .slick-slide > div { height: 100%; }
