.adv-admin-lists {
    margin: 0 0 100px 0;
}

.adv-admin-lists h1 {
    margin: 20px 0
}

.adv-admin-lists .add-item {
    color: #949494;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
    margin: 0 0 0 15px;
    position: relative;
    top: 10px
}

.adv-admin-lists .custom-grid {
    margin-top: -50px
}

.adv-admin-lists .custom-grid .no-wrap {
    white-space: nowrap
}

.adv-admin-lists .display-pill {
    background: #d0edfc;
    border-radius: 12px;
    display: inline-block;
    padding: 3px 10px;
    color: #000;
    font-weight: 600;
    margin-right: 5px;
    margin-bottom: 10px
}

.adv-admin-lists .display-pill .fa-times-circle {
    position: relative;
    top: 0;
    left: 0;
    display: inline-block;
    color: #000;
    background-color: #fff;
    border-radius: 100%;
    cursor: pointer
}

.adv-admin-list .add-availability-pill {
    color: #949494;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
    float: right
}

.adv-admin-list .availability-wrapper .display-pill {
    background: #d0edfc;
    border-radius: 12px;
    display: inline-block;
    padding: 3px 10px;
    color: #000;
    font-weight: 600;
    margin-right: 5px;
    margin-bottom: 10px
}

.adv-admin-list .availability-wrapper .display-pill .fa-times-circle {
    position: relative;
    top: 0;
    left: 0;
    display: inline-block;
    color: #000;
    background-color: #fff;
    border-radius: 100%;
    cursor: pointer
}

.adv-admin-list .availability-wrapper .no-pills {
    color: #777;
    font-size: 16px
}