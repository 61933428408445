/** MultiSelect **/
.p-multiselect {
  display: inline-block;
  position: relative;
  width: auto;
  cursor: pointer;
}

.p-multiselect .p-multiselect-trigger {
  border-right: none;
  border-top: none;
  border-bottom: none;
  cursor: pointer;
  width: 1.5em;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  padding: 0 0.25em;
}

.p-multiselect .p-multiselect-trigger .fa {
  margin-top: 0.4em;
  margin-left: -0.125em;
}

.p-multiselect .p-multiselect-label-container {
  overflow: hidden;
}

.p-multiselect .p-multiselect-label {
  display: block;
  padding: 0.25em 2em 0.25em 0.25em;
  width: auto;
  border: none;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.p-multiselect.p-state-disabled .p-multiselect-trigger,
.p-multiselect.p-state-disabled .p-multiselect-label {
  cursor: auto;
}

.p-multiselect-panel {
  padding: 0.2em;
  position: absolute;
  min-width: 10em;
}

.p-multiselect .p-multiselect-panel {
  min-width: 100%;
}

.p-multiselect-panel .p-multiselect-items-wrapper {
  overflow: auto;
  position: relative;
  padding: 0.2em 0;
}

.p-multiselect-panel .p-multiselect-list {
  border: 0 none;
}

.p-multiselect-panel .p-multiselect-item {
  border: 0 none;
  cursor: pointer;
  font-weight: normal;
  margin: 1px 0;
  padding: 0.125em 0.25em;
  text-align: left;
  white-space: nowrap;
  display: block;
  position: relative;
}

.p-multiselect-panel .p-multiselect-item .p-chkbox {
  display: inline-block;
  vertical-align: middle;
}

.p-multiselect-panel .p-multiselect-item label {
  display: inline-block;
  vertical-align: middle;
}

.p-multiselect-header {
  margin-bottom: 0.3em;
  padding: 0.25em;
  position: relative;
  text-align: left;
}

.p-multiselect-header .p-chkbox {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.p-multiselect-header .p-multiselect-filter-container {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 65%;
}

.p-multiselect-header .p-multiselect-filter-container .fa {
  position: absolute;
  top: 0.25em;
  left: 0.125em;
}

.p-multiselect-header .p-inputtext {
  padding: 0.125em 0.125em 0.125em 1.25em;
  width: 100%;
}

.p-multiselect-header .p-multiselect-close {
  position: absolute;
  right: 0.375em;
  top: 0.375em;
  display: block;
  font-size: 1em;
  border: 0 none;
}

.p-multiselect-header a.p-multiselect-all,
.p-multiselect-header a.p-multiselect-none {
  float: left;
  margin-right: 10px;
  display: block;
}

.p-multiselect-header .p-multiselect-close.p-state-hover {
  padding: 0px;
}

.p-fluid .p-multiselect {
  width: 100%;
  box-sizing: border-box;
}
