
.custom-grid .hidden-search-wrapper {
    width: 300px;
    float: right;
    margin-bottom: 20px
}

.custom-grid .hidden-search-wrapper .hidden-search-box {
    position: relative;
    top: 30px;
    border: none;
    box-shadow: none;
    font-size: 14px;
    height: 40px
}

.custom-grid .hidden-search-wrapper .hidden-search-box.is-visible {
    border: 1px solid #ced4da;
    background-color: #f1f1f1!important;
    outline: 0
}

.custom-grid .hidden-search-wrapper .glyphicon-search {
    float: right;
    right: 10px;
    pointer-events: none;
    top: 1px;
    font-size: 18px
}

.custom-grid table {
    width: 100%;
    font-size: 14px;
    margin-bottom: 10px
}

.custom-grid table thead tr {
    border-bottom: 4px solid #eee
}

.custom-grid table thead tr th {
    font-weight: 600;
    padding: 10px 10px 10px 15px
}

.custom-grid table thead tr th.sort {
    cursor: pointer
}

.custom-grid table thead tr th.asc:after {
    content: "\F077"
}

.custom-grid table thead tr th.asc:after,.custom-grid table thead tr th.desc:after {
    font-family: FontAwesome;
    font-size: 12px;
    font-weight: 400;
    margin-left: 10px;
    position: absolute
}

.custom-grid table thead tr th.desc:after {
    content: "\F078"
}

.custom-grid table tbody tr {
    border-bottom: 2px solid #eee
}

.custom-grid table tbody tr td {
    padding: 10px 10px 10px 15px
}

.custom-grid table tbody tr td:not(:first-of-type) {
    border-left: 2px solid #eee
}

.custom-grid table tbody tr td .edit {
    float: right;
    cursor: pointer;
    visibility: hidden;
    margin-right: 5px
}

.custom-grid table tbody tr:nth-child(odd) {
    background-color: #fafafb
}

.custom-grid table tbody tr:hover {
    background-color: #f7f7f7
}

.custom-grid table tbody tr:hover .edit {
    visibility: visible
}