.p-widget,
.p-widget * {
  box-sizing: border-box;
}

.p-helper-hidden {
  display: none;
}

.p-helper-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.p-helper-hidden-accessible input,
.p-helper-hidden-accessible select {
  transform: scale(0);
}

.p-helper-reset {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  line-height: 1.3;
  text-decoration: none;
  font-size: 100%;
  list-style: none;
}

.p-helper-clearfix:before,
.p-helper-clearfix:after {
  content: " ";
  display: table;
}

.p-helper-clearfix:after {
  clear: both;
}

.p-helper-clearfix {
  zoom: 1;
}

.p-helper-zfix {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  filter: Alpha(Opacity=0);
}

.p-state-disabled {
  cursor: default !important;
}

.p-state-disabled a {
  cursor: default !important;
}

.p-icon {
  display: block;
  text-indent: -99999px;
  overflow: hidden;
  background-repeat: no-repeat;
}

.p-widget-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.p-resizable {
  position: relative;
}

.p-resizable-handle {
  position: absolute;
  font-size: 0.1px;
  display: block;
}

.p-resizable-disabled .p-resizable-handle,
.p-resizable-autohide .p-resizable-handle {
  display: none;
}

.p-resizable-n {
  cursor: n-resize;
  height: 7px;
  width: 100%;
  top: -5px;
  left: 0;
}

.p-resizable-s {
  cursor: s-resize;
  height: 7px;
  width: 100%;
  bottom: -5px;
  left: 0;
}

.p-resizable-e {
  cursor: e-resize;
  width: 7px;
  right: -5px;
  top: 0;
  height: 100%;
}

.p-resizable-w {
  cursor: w-resize;
  width: 7px;
  left: -5px;
  top: 0;
  height: 100%;
}

.p-resizable-se {
  cursor: se-resize;
  width: 12px;
  height: 12px;
  right: 1px;
  bottom: 1px;
}

.p-resizable-sw {
  cursor: sw-resize;
  width: 9px;
  height: 9px;
  left: -5px;
  bottom: -5px;
}

.p-resizable-nw {
  cursor: nw-resize;
  width: 9px;
  height: 9px;
  left: -5px;
  top: -5px;
}

.p-resizable-ne {
  cursor: ne-resize;
  width: 9px;
  height: 9px;
  right: -5px;
  top: -5px;
}

.p-shadow {
  -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
}

.p-unselectable-text {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.p-scrollbar-measure {
  width: 100px;
  height: 100px;
  overflow: scroll;
  position: absolute;
  top: -9999px;
}

.p-overflow-hidden {
  overflow: hidden;
}

@-webkit-keyframes p-fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes p-fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

input[type="button"],
input[type="submit"],
input[type="reset"],
input[type="file"]::-webkit-file-upload-button,
button {
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}

.p-accordion {
  width: 100%;
}

.p-accordion .p-accordion-header {
  cursor: pointer;
  position: relative;
  margin-top: 1px;
  zoom: 1;
}

.p-accordion .p-accordion-header a {
  display: block;
  padding: 0.5em;
}

.p-accordion .p-accordion-header a > span {
  display: inline-block;
  vertical-align: middle;
}

.p-accordion .p-accordion-content {
  padding: 1em;
  border-top: 0;
  zoom: 1;
}

.p-accordion .p-accordion-header.p-state-disabled,
.p-accordion .p-accordion-header.p-state-disabled a {
  cursor: default;
}

.p-accordion .p-accordion-content-wrapper-collapsed {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
}

.p-accordion .p-accordion-content-wrapper-expanded {
  max-height: 1000px;
  transition: max-height 1s ease-in-out;
}

.p-accordion .p-accordion-content-wrapper-expanding {
  overflow: hidden;
}
/** Breadcrumb **/
.p-breadcrumb {
  margin: 0;
  padding: 0;
  padding: 0.3em;
}

.p-breadcrumb ul {
  margin: 0;
  padding: 0;
}

.p-breadcrumb ul li {
  display: inline-block;
  vertical-align: middle;
}

.p-breadcrumb ul li .p-menuitem-link {
  text-decoration: none;
}

.p-autocomplete {
  width: auto;
  zoom: 1;
  cursor: pointer;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  position: relative;
  display: inline-block;
}

.p-autocomplete .p-autocomplete-dropdown {
  height: 100%;
  width: 2em;
  margin-right: 0;
  vertical-align: top;
}

.p-autocomplete .p-autocomplete-input {
  padding-right: 1.5em;
}

.p-autocomplete-loader {
  position: absolute;
  right: 0.25em;
  top: 50%;
  margin-top: -0.5em;
}

.p-autocomplete-query {
  font-weight: bold;
}

.p-autocomplete .p-autocomplete-panel {
  min-width: 100%;
}

.p-autocomplete-panel {
  position: absolute;
  overflow: auto;
  display: none;
}

.p-autocomplete-panel .p-autocomplete-list {
  padding: 0.4em;
  border: 0 none;
}

.p-autocomplete-panel .p-autocomplete-list-item {
  border: 0 none;
  cursor: pointer;
  font-weight: normal;
  margin: 1px 0;
  padding: 0.186em 0.313em;
  text-align: left;
}

.p-autocomplete .p-button-icon-only,
.p-autocomplete .p-button-icon-only:enabled:hover,
.p-autocomplete .p-button-icon-only:enabled:focus,
.p-autocomplete .p-button-icon-only:enabled:active {
  border-left: 0 none;
}

/* Multiple Selection */
.p-autocomplete-multiple-container {
  display: inline-block;
  vertical-align: middle;
}

.p-autocomplete-multiple-container.p-inputtext {
  clear: left;
  cursor: text;
  list-style-type: none;
  margin: 0;
  overflow: hidden;
  padding: 0 1.5em 0 0.25em;
}

.p-autocomplete-token {
  cursor: default;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  padding: 0.125em 0.5em;
  white-space: nowrap;
  position: relative;
  margin-right: 0.125em;
  border: 0 none;
  font-size: 0.9em;
}

.p-autocomplete-token-label {
  display: block;
  margin-right: 2em;
}

.p-autocomplete-token-icon {
  margin-top: -0.5em;
  position: absolute;
  right: 0.2em;
  top: 50%;
  cursor: pointer;
}

.p-autocomplete-input-token {
  display: inline-block;
  vertical-align: middle;
  list-style-type: none;
  margin: 0 0 0 0.125em;
  padding: 0.25em 0.25em 0.25em 0;
}

.p-autocomplete-input-token input {
  border: 0 none;
  width: 10em;
  outline: medium none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}

.p-autocomplete-dd .p-autocomplete-loader {
  right: 2.25em;
}

.p-autocomplete-dd input.p-corner-all,
.p-autocomplete-dd .p-autocomplete-multiple-container.p-corner-all {
  -moz-border-radius-topright: 0px;
  -webkit-border-top-right-radius: 0px;
  border-top-right-radius: 0px;
  -moz-border-radius-bottomright: 0px;
  -webkit-border-bottom-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.p-autocomplete-dd .p-autocomplete-dropdown.p-corner-all {
  -moz-border-radius-topleft: 0px;
  -webkit-border-top-left-radius: 0px;
  border-top-left-radius: 0px;
  -moz-border-radius-bottomleft: 0px;
  -webkit-border-bottom-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

/** AutoComplete **/
.p-fluid .p-autocomplete,
.p-fluid .p-autocomplete-input {
  width: 100%;
}

.p-fluid .p-autocomplete.p-autocomplete-dd .p-autocomplete-input,
.p-fluid .p-autocomplete.p-autocomplete-dd .p-autocomplete-multiple-container {
  width: calc(100% - 2em);
}

.p-fluid .p-autocomplete .p-autocomplete-dropdown.p-button {
  width: 2em;
}
/* Button */
.p-button {
  display: inline-block;
  padding: 0.25em 0.5em;
  margin-right: 0.25em;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  zoom: 1;
  overflow: visible; /* the overflow property removes extra width in IE */
}

.p-button-icon-only {
  padding: 0.25em;
}

.p-button-icon-left {
  margin-right: 0.25em;
}

.p-button-icon-right {
  margin-left: 0.25em;
}

.p-button-icon-only .p-button-icon {
  margin: 0;
}

/*button sets*/
.p-buttonset .p-button {
  margin-left: 0;
  margin-right: 0;
}

/* workarounds */
button.p-button::-moz-focus-inner {
  border: 0;
  padding: 0; /* reset extra padding in Firefox */
}

/** Fluid **/
.p-fluid .p-button {
  width: 100%;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

/** ButtonSet **/
.p-fluid .p-buttonset {
  width: 100%;
}

.p-fluid .p-buttonset.p-buttonset-1 .p-button {
  width: 100%;
}

.p-fluid .p-buttonset.p-buttonset-2 .p-button {
  width: 50%;
}

.p-fluid .p-buttonset.p-buttonset-3 .p-button {
  width: 33.3%;
}

.p-fluid .p-buttonset.p-buttonset-4 .p-button {
  width: 25%;
}

.p-fluid .p-buttonset.p-buttonset-5 .p-button {
  width: 20%;
}

.p-fluid .p-buttonset.p-buttonset-6 .p-button {
  width: 16.6%;
}

@media (max-width: 640px) {
  .p-fluid .p-buttonset.p-buttonset-1 .p-button,
  .p-fluid .p-buttonset.p-buttonset-2 .p-button,
  .p-fluid .p-buttonset.p-buttonset-3 .p-button,
  .p-fluid .p-buttonset.p-buttonset-4 .p-button,
  .p-fluid .p-buttonset.p-buttonset-5 .p-button,
  .p-fluid .p-buttonset.p-buttonset-6 .p-button {
    width: 100%;
  }
}

/* Severity Buttons */
/* Secondary */
.p-button.p-button-secondary.p-state-default,
.p-splitbutton.p-button-secondary .p-button.p-state-default {
  background-color: #ffffff;
  border-color: #cccccc;
  color: #373a3c;
}

.p-button.p-button-secondary:enabled:hover,
.p-button.p-button-secondary:focus,
.p-splitbutton.p-button-secondary .p-button:enabled:hover,
.p-splitbutton.p-button-secondary .p-button:focus {
  background-color: #f2f2f2;
  border-color: #cccccc;
  color: #373a3c;
}

.p-button.p-button-secondary:enabled:active,
.p-splitbutton.p-button-secondary .p-button:enabled:active {
  background-color: #e6e6e6;
  border-color: #cccccc;
  color: #373a3c;
}

/* Success */
.p-button.p-button-success.p-state-default,
.p-splitbutton.p-button-success .p-button.p-state-default {
  background-color: #5cb85c;
  border-color: #5cb85c;
  color: #ffffff;
}

.p-button.p-button-success:enabled:hover,
.p-button.p-button-success:focus,
.p-splitbutton.p-button-success .p-button:enabled:hover,
.p-splitbutton.p-button-success .p-button:focus {
  background-color: #4cae4c;
  border-color: #5cb85c;
}

.p-button.p-button-success:enabled:active,
.p-splitbutton.p-button-success .p-button:enabled:active {
  background-color: #449d44;
  border-color: #5cb85c;
}

/* Info */
.p-button.p-button-info.p-state-default,
.p-splitbutton.p-button-info .p-button.p-state-default {
  background-color: #5bc0de;
  border-color: #5bc0de;
  color: #ffffff;
}

.p-button.p-button-info:enabled:hover,
.p-button.p-button-info:focus,
.p-splitbutton.p-button-info .p-button:enabled:hover,
.p-splitbutton.p-button-info .p-button:focus {
  background-color: #46b8da;
  border-color: #5bc0de;
}

.p-button.p-button-info:enabled:active,
.p-splitbutton.p-button-info .p-button:enabled:active {
  background-color: #31b0d5;
  border-color: #5bc0de;
}

/* Warning */
.p-button.p-button-warning.p-state-default,
.p-splitbutton.p-button-warning .p-button.p-state-default {
  background-color: #f0ad4e;
  border-color: #f0ad4e;
  color: #ffffff;
}

.p-button.p-button-warning:enabled:hover,
.p-button.p-button-warning:focus,
.p-splitbutton.p-button-warning .p-button:enabled:hover,
.p-splitbutton.p-button-warning .p-button:focus {
  background-color: #eea236;
  border-color: #f0ad4e;
}

.p-button.p-button-warning:enabled:active,
.p-splitbutton.p-button-warning .p-button:enabled:active {
  background-color: #ec971f;
  border-color: #f0ad4e;
}

/* Danger */
.p-button.p-button-danger.p-state-default,
.p-splitbutton.p-button-danger .p-button.p-state-default {
  background-color: #d9534f;
  border-color: #d9534f;
  color: #ffffff;
}

.p-button.p-button-danger:enabled:hover,
.p-button.p-button-danger:focus,
.p-splitbutton.p-button-danger .p-button:enabled:hover,
.p-splitbutton.p-button-danger .p-button:focus {
  background-color: #d43f3a;
  border-color: #d9534f;
}

.p-button.p-button-danger:enabled:active,
.p-splitbutton.p-button-danger .p-button:enabled:active {
  background-color: #c9302c;
  border-color: #d9534f;
}

.p-calendar {
  position: relative;
  display: inline-block;
}

.p-calendar .p-calendar-button {
  position: absolute;
  height: 100%;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  width: 2em;
  border-left: 0 none;
  margin-right: 0;
}

.p-calendar .p-calendar-button:enabled:hover,
.p-calendar .p-calendar-button:focus {
  border-left: 0 none;
}

/* Fluid */
.p-fluid .p-calendar {
  width: 100%;
}

.p-fluid .p-calendar-button {
  width: 2em;
}

.p-fluid .p-datepicker-buttonbar button {
  width: auto;
}

.p-fluid .p-calendar.p-calendar-w-btn .p-inputtext {
  width: calc(100% - 2em);
}

/* DatePicker */
.p-datepicker {
  width: 17em;
  padding: 0.2em;
  display: none;
  position: absolute;
}

.p-datepicker.p-datepicker-inline {
  display: inline-block;
  position: static;
}

.p-datepicker .p-datepicker-header {
  position: relative;
  padding: 0.2em 0;
}

.p-datepicker .p-datepicker-prev,
.p-datepicker .p-datepicker-next {
  position: absolute;
  top: 0.125em;
  width: 1.8em;
  height: 1.8em;
  cursor: pointer;
}

.p-datepicker .p-datepicker-prev {
  left: 0.125em;
}

.p-datepicker .p-datepicker-next {
  right: 0.125em;
}

.p-datepicker .p-datepicker-prev span,
.p-datepicker .p-datepicker-next span {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -0.5em;
}

.p-datepicker .p-datepicker-prev span {
  margin-left: -0.25em;
}

.p-datepicker .p-datepicker-next span {
  margin-left: -0.125em;
}

.p-datepicker .p-datepicker-title {
  margin: 0 2.3em;
  line-height: 1.8em;
  text-align: center;
}

.p-datepicker .p-datepicker-title .p-datepicker-month {
  margin-right: 0.25em;
}

.p-datepicker .p-datepicker-title select {
  font-size: 1em;
  margin: 0.125em 0;
  vertical-align: middle;
}

.p-datepicker select.p-datepicker-month {
  width: 55%;
}

.p-datepicker select.p-datepicker-year {
  width: 35%;
}

.p-datepicker select.p-datepicker-month {
  margin-right: 0.25em;
}

.p-datepicker table {
  width: 100%;
  border-collapse: collapse;
  margin: 0 0 0.4em;
}

.p-datepicker th {
  padding: 0.7em 0.3em;
  text-align: center;
  font-weight: bold;
  border: 0;
}

.p-datepicker td {
  border: 0;
  padding: 0.125em;
}

.p-datepicker td span,
.p-datepicker td a {
  display: block;
  padding: 0.2em;
  text-align: right;
  text-decoration: none;
}

.p-datepicker .p-datepicker-buttonpane {
  background-image: none;
  margin: 0.7em 0 0 0;
  padding: 0 0.2em;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
}

.p-datepicker .p-datepicker-buttonpane button {
  float: right;
  margin: 0.5em 0.2em 0.4em;
  cursor: pointer;
  padding: 0.2em 0.6em 0.3em 0.6em;
  width: auto;
  overflow: visible;
}

.p-datepicker .p-datepicker-buttonpane button.p-datepicker-current {
  float: left;
}

/* with multiple calendars */
.p-datepicker.p-datepicker-multi {
  width: auto;
}

.p-datepicker-multi .p-datepicker-group {
  float: left;
}

.p-datepicker-multi .p-datepicker-group table {
  width: 95%;
  margin: 0 auto 0.4em;
}

.p-datepicker-multi-2 .p-datepicker-group {
  width: 50%;
}

.p-datepicker-multi-3 .p-datepicker-group {
  width: 33.3%;
}

.p-datepicker-multi-4 .p-datepicker-group {
  width: 25%;
}

.p-datepicker-multi .p-datepicker-group-last .p-datepicker-header,
.p-datepicker-multi .p-datepicker-group-middle .p-datepicker-header {
  border-left-width: 0;
}

.p-datepicker-multi .p-datepicker-buttonpane {
  clear: left;
}

.p-datepicker-row-break {
  clear: both;
  width: 100%;
  font-size: 0;
}

.p-datepicker .p-datepicker-buttonbar {
  border-left: 0 none;
  border-right: 0 none;
  border-bottom: 0 none;
  padding: 0.2em;
}

.p-datepicker .p-datepicker-buttonbar > .p-g > div:last-child {
  text-align: right;
}

.p-datepicker .p-datepicker-buttonbar > .p-g > div {
  padding: 0;
}

.p-calendar.p-calendar-w-btn input {
  -moz-border-radius-topright: 0px;
  -webkit-border-top-right-radius: 0px;
  -khtml-border-top-right-radius: 0px;
  border-top-right-radius: 0px;
  -moz-border-radius-bottomright: 0px;
  -webkit-border-bottom-right-radius: 0px;
  -khtml-border-bottom-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.p-timepicker {
  text-align: center;
  padding: 0.5em 0;
}

.p-timepicker > div {
  display: inline-block;
  margin-left: 0.5em;
  min-width: 1.5em;
}

.p-timepicker > .p-minute-picker,
.p-timepicker > .p-second-picker {
  margin-left: 0;
}

.p-timepicker > .p-separator {
  margin-left: 0px;
  min-width: 0.75em;
}

.p-timepicker > .p-separator a {
  visibility: hidden;
}

.p-timepicker > div a {
  display: block;
  opacity: 0.7;
  filter: Alpha(Opacity=70);
}

.p-timepicker > div a:hover {
  display: block;
  opacity: 1;
  filter: Alpha(Opacity=100);
}

.p-chart {
  position: relative;
}

.p-card-header img {
  width: 100%;
}

.p-card-body {
  padding: 1em;
}

.p-card-title {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 0.5em;
}

.p-card-subtitle {
  opacity: 0.7;
  margin-bottom: 0.5em;
  margin-top: -0.25em;
  font-weight: bold;
}

.p-card-content {
  line-height: 1.5;
}

.p-card-footer {
  padding-top: 1em;
}

.p-card-shadow {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.p-chkbox {
  display: inline-block;
  cursor: pointer;
  vertical-align: middle;
  margin-right: 0.25em;
}

.p-chkbox .p-chkbox-box {
  width: 1.125em;
  height: 1.125em;
  line-height: 1.125em;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  text-align: center;
}

.p-chkbox .p-chkbox-icon {
  display: block;
}

.p-chkbox-label {
  vertical-align: middle;
}

.p-chkbox + label {
  vertical-align: middle;
}

.p-chips > ul.p-inputtext {
  clear: left;
  cursor: text;
  list-style-type: none;
  margin: 0;
  overflow: hidden;
  padding: 0 0.25em;
}

.p-chips-token {
  cursor: default;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  padding: 0.125em 0.5em;
  white-space: nowrap;
  position: relative;
  margin-right: 0.125em;
  border: 0 none;
  font-size: 0.9em;
}

.p-chips-token .p-chips-token-label {
  display: block;
  margin-right: 2em;
}

.p-chips > .p-state-disabled .p-chips-token-label {
  margin-right: 0;
}

.p-chips-token .p-chips-token-icon {
  margin-top: -0.5em;
  position: absolute;
  right: 0.2em;
  top: 50%;
  cursor: pointer;
}

.p-chips-input-token {
  display: inline-block;
  vertical-align: middle;
  list-style-type: none;
  margin: 0 0 0 0.125em;
  padding: 0.25em 0.25em 0.25em 0;
}

.p-chips-input-token .p-inputtext {
  border: 0 none;
  width: 10em;
  outline: medium none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}

.p-colorpicker {
  display: inline-block;
}

.p-colorpicker-dragging {
  cursor: pointer;
}

.p-colorpicker-overlay {
  position: relative;
}

.p-colorpicker-panel {
  position: relative;
  width: 193px;
  height: 166px;
  background-color: #323232;
  border-color: #191919;
}

.p-colorpicker-overlay-panel {
  display: none;
  position: absolute;
}

.p-colorpicker-preview {
  width: 2em;
  cursor: pointer;
}

.p-colorpicker-panel .p-colorpicker-content {
  position: relative;
}

.p-colorpicker-panel .p-colorpicker-color-selector {
  width: 150px;
  height: 150px;
  top: 8px;
  left: 8px;
  position: absolute;
}

.p-colorpicker-panel .p-colorpicker-color {
  width: 150px;
  height: 150px;
  /* background: transparent url("../../../public/Images/color.png") no-repeat left top; */
}

.p-colorpicker-panel .p-colorpicker-color-handle {
  position: absolute;
  top: 0px;
  left: 150px;
  border-radius: 100%;
  width: 10px;
  height: 10px;
  border: 1px solid #ffffff;
  margin: -5px 0 0 -5px;
  cursor: pointer;
}

.p-colorpicker-panel .p-colorpicker-hue {
  /* background: transparent url("../../../public/Images/hue.png") no-repeat left top; */
  width: 17px;
  height: 150px;
  top: 8px;
  left: 167px;
  position: absolute;
  opacity: 0.85;
}

.p-colorpicker-panel .p-colorpicker-hue-handle {
  position: absolute;
  top: 150px;
  left: 0px;
  width: 21px;
  margin-left: -2px;
  margin-top: -5px;
  height: 10px;
  border: 2px solid #ffffff;
  opacity: 0.85;
  cursor: pointer;
}

.p-colorpicker-panel.p-state-disabled .p-colorpicker-hue-handle,
.p-colorpicker-panel.p-state-disabled .p-colorpicker-color-handle {
  opacity: 0.5;
}

.p-datagrid .p-paginator {
  text-align: center;
  border-top: 0 none;
}

.p-datagrid-column {
  padding: 0.25em;
}

.p-datagrid-content-empty {
  padding: 0.25em 0.625em;
}

.p-datagrid .p-datagrid-header,
.p-datagrid .p-datagrid-footer {
  text-align: center;
  padding: 0.5em 0.75em;
}

.p-datagrid .p-datagrid-header {
  border-bottom: 0 none;
}

.p-datagrid .p-datagrid-footer {
  border-top: 0 none;
}

.p-datagrid .p-paginator-top {
  border-bottom: 0 none;
}

.p-datagrid .p-paginator-bottom {
  border-top: 0 none;
}

.p-datalist .p-datalist-header,
.p-datalist .p-datalist-footer {
  text-align: center;
  padding: 0.5em 0.75em;
}

.p-datalist .p-datalist-header {
  border-bottom: 0 none;
}

.p-datalist .p-datalist-footer {
  border-top: 0 none;
}

.p-datalist .p-paginator {
  border-top: 0 none;
}

.p-datalist .p-datalist-data {
  margin: 0;
  padding: 0;
}

.p-datalist .p-datalist-data > li {
  list-style-type: none;
}

.p-datalist .p-datalist-emptymessage {
  padding: 0.5em 0.75em;
}

.p-datalist.p-datalist-scrollable .p-datalist-content {
  overflow: auto;
}

.p-dataview .p-paginator {
  text-align: center;
}

.p-dataview-column {
  padding: 0.25em;
}

.p-dataview-content-empty {
  padding: 0.25em 0.625em;
}

.p-dataview .p-dataview-header,
.p-dataview .p-dataview-footer {
  text-align: center;
  padding: 0.5em 0.75em;
}

.p-dataview .p-dataview-header {
  border-bottom: 0 none;
}

.p-dataview .p-dataview-footer {
  border-top: 0 none;
}

.p-dataview .p-paginator-top {
  border-bottom: 0 none;
}

.p-dataview .p-paginator-bottom {
  border-top: 0 none;
}

.p-dataview.p-dataview-list > .p-dataview-content > div.p-g > div {
  width: 100%;
}

.p-datatable {
  position: relative;
}

.p-datatable table {
  border-collapse: collapse;
  table-layout: auto;
}

.p-datatable .p-datatable-header,
.p-datatable .p-datatable-caption,
.p-datatable .p-datatable-footer {
  text-align: center;
  padding: 0.5em 0.75em;
  box-sizing: border-box;
}

.p-datatable .p-datatable-caption,
.p-datatable .p-datatable-header {
  border-bottom: 0 none;
}

.p-datatable .p-datatable-footer {
  border-top: 0 none;
}

.p-datatable thead th,
.p-datatable tfoot td {
  text-align: center;
}

.p-datatable thead tr {
  border-width: 0;
}

.p-datatable .p-datatable-thead > tr > th,
.p-datatable .p-datatable-tfoot > tr > td,
.p-datatable .p-datatable-data > tr > td {
  box-sizing: border-box;
  padding: 0.25em 0.5em;
  border-width: 1px;
  border-style: solid;
}

.p-datatable .p-datatable-tfoot > tr > td,
.p-datatable .p-datatable-data > tr > td {
  border-color: inherit;
}

.p-datatable .p-datatable-thead > tr > th {
  border-top-color: white !important;
  border-left-color: white !important;
  border-bottom-color: #c8c8c8 !important;
  border-right-color: #c8c8c8 !important;
  background: #fff;
}

.p-datatable .p-datatable-thead > tr > th:last-of-type {
  border-right-color: white !important;
}

.p-datatable .p-datatable-tbody > tr > td {
  border-top-color: white !important;
  border-left-color: white !important;
  border-bottom-color: inherit;
  font-family: Roboto, "Trebuchet MS", Arial, Helvetica, sans-serif !important;
  font-weight: 400;
  text-align: left;
  line-height: 1;
  border: 1px solid #c8c8c8;
  border-width: 1px;
  padding: 0.571rem 0.857rem;
}

.p-datatable .p-datatable-tbody > tr > td:last-of-type {
  border-right-color: white !important;
}

.p-datatable-auto-layout > .p-datatable-wrapper > table {
  table-layout: auto;
}

.p-datatable.p-datatable-resizable .p-datatable-thead > tr > th,
.p-datatable.p-datatable-resizable .p-datatable-tfoot > tr > td,
.p-datatable.p-datatable-resizable .p-datatable-data > tr > td {
  overflow-x: hidden;
}

.p-datatable .p-datatable-thead > tr > th,
.p-datatable .p-datatable-tfoot > tr > td {
  font-weight: normal;
}

.p-datatable tbody {
  outline: 0;
}

.p-datatable .p-sortable-column-icon {
  display: inline-block;
  margin-left: 0.125em;
}

.p-datatable .p-sortable-column.p-highlight {
  color: #000000 !important;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  content: "pi pi-sort-up" !important;
  color: #000000 !important;
  font-size: 18px;
  margin-top: 2px;
}

.p-datatable
  .p-datatable-thead
  .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover {
  background-color: transparent;
}

.p-datatable .p-datatable-thead .p-sortable-column:focus {
  box-shadow: none;
}

.pi-sort-amount-up-alt:before {
  margin-left: 5px;
  margin-right: 5px;
}

.pi-sort-amount-down:before {
  margin-left: 5px;
  margin-right: 5px;
}

/* Updating sort icon positing and format*/
.p-datatable .p-sortable-column .p-sortable-column-icon {
  color: #000000;
  margin-left: 0.5rem;
  font-size: 18px;
}

/* Sortable icon formatting (currently removed)*/
.pi-sort-alt:before {
  content: "" !important;
}

.p-datatable tr.p-state-highlight {
  cursor: pointer;
}
/* Scrollable */
.p-datatable-scrollable-body {
  overflow: auto;
}

.p-datatable-scrollable .p-datatable-scrollable-header,
.p-datatable-scrollable .p-datatable-scrollable-footer {
  position: relative;
  border: 0 none;
  overflow: hidden;
}

.p-datatable-scrollable .p-datatable-scrollable-header td {
  font-weight: normal;
}

.p-datatable .p-datatable-scrollable-body {
  min-height: 0%;
}

.p-datatable .p-datatable-data tr.p-state-hover,
.p-datatable .p-datatable-data tr.p-state-highlight {
  border-color: inherit;
  font-weight: inherit;
  cursor: pointer;
}

.p-datatable .p-datatable-data tr.p-rowgroup-header td a,
.p-datatable
  .p-datatable-data
  tr.p-rowgroup-header
  td
  span.p-rowgroup-header-name {
  display: inline-block;
  vertical-align: middle;
}

.p-datatable-scrollable-theadclone {
  height: 0;
}

.p-datatable-scrollable-theadclone tr {
  height: 0;
}

.p-datatable-scrollable-theadclone th.p-state-default {
  height: 0;
  border-bottom-width: 0;
  border-top-width: 0;
  padding-top: 0;
  padding-bottom: 0;
  outline: 0 none;
}

.p-datatable-scrollable-theadclone th span.p-column-title {
  display: block;
  height: 0;
}

.p-datatable .p-paginator {
  padding: 0.125em;
}

.p-datatable .p-paginator-top {
  border-bottom-width: 0;
}

.p-datatable .p-paginator-bottom {
  border-top-width: 0;
}

.p-datatable-rtl {
  direction: rtl;
}

.p-datatable-rtl.p-datatable thead th,
.p-datatable-rtl.p-datatable tfoot td {
  text-align: right;
}
/* Row Toggler */
.p-row-toggler {
  cursor: pointer;
}
/* Resizable */
.p-datatable .p-column-resizer {
  display: block;
  position: absolute !important;
  top: 0;
  right: 0;
  margin: 0;
  width: 0.5em;
  height: 100%;
  padding: 0px;
  cursor: col-resize;
  border: 1px solid transparent;
}

.p-datatable.p-datatable-resizable
  .p-datatable-thead
  > tr
  > th:last-child
  .p-column-resizer {
  display: none;
}

.p-datatable .p-column-resizer-helper {
  width: 1px;
  position: absolute;
  z-index: 10;
  display: none;
}

.p-datatable-resizable {
  padding-bottom: 1px; /*fix for webkit overlow*/
}

.p-datatable-resizable > .p-datatable-tablewrapper {
  overflow-x: auto;
}

.p-datatable-resizable thead th,
.p-datatable-resizable tbody td,
.p-datatable-resizable tfoot td {
  white-space: nowrap;
}

.p-datatable-resizable th.p-resizable-column {
  background-clip: padding-box;
  position: relative;
}
/** Reflow **/
.p-datatable-reflow .p-datatable-data td .p-column-title {
  display: none;
}
/* Filter */
.p-datatable {
  display: block;
  width: 100%;
  box-sizing: border-box;
  margin-top: 0.25em;
}

.p-column-filter {
  display: none;
  width: 100%;
  box-sizing: border-box;
  margin-top: 0.25em;
}
/* Editing */
.p-datatable .p-editable-column input {
  width: 100%;
  outline: 0;
}

.p-datatable .p-datatable-data > tr > td.p-editable-column {
  padding: 0.5em;
}

.p-datatable .p-datatable-data > tr > td.p-editable-column.p-cell-editing {
  padding: 1px;
}

.p-datatable-stacked thead th,
.p-datatable-stacked tfoot td {
  display: none !important;
}

.p-datatable.p-datatable-stacked .p-datatable-data > tr > td {
  text-align: left;
  display: block;
  border: 0 none;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  float: left;
  clear: left;
}

.p-datatable.p-datatable-stacked .p-datatable-data.p-widget-content {
  border: 0 none;
}

.p-datatable-stacked .p-datatable-data tr.p-widget-content {
  border-left: 0 none;
  border-right: 0 none;
}

.p-datatable-stacked .p-datatable-data td .p-column-title {
  padding: 0.4em;
  min-width: 30%;
  display: inline-block;
  margin: -0.4em 1em -0.4em -0.4em;
  font-weight: bold;
}

.p-datatable .p-selection-column .p-chkbox,
.p-datatable .p-selection-column .p-radiobutton {
  margin: 0;
  display: block;
}

.p-datatable .p-selection-column .p-chkbox-box,
.p-datatable .p-selection-column .p-radiobutton-box {
  display: block;
  box-sizing: border-box;
  margin: 0;
}

.p-datatable-scrollable-wrapper {
  position: relative;
}

.p-datatable-scrollable-view {
}

.p-datatable-frozen-view .p-datatable-scrollable-body {
  overflow: hidden;
}

.p-datatable-frozen-view + .p-datatable-scrollable-view {
  position: absolute;
  top: 0px;
}

.p-datatable .p-datatable-load-status {
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
}

.p-datatable.p-datatable-virtual-scrollable
  .p-datatable-scrollable-table-wrapper {
  position: relative;
}

.p-datatable.p-datatable-virtual-scrollable
  .p-datatable-scrollable-table-wrapper
  > table {
  position: absolute;
  top: 0px;
  left: 0px;
}

.p-datatable-loader {
  position: absolute;
  width: 100%;
  height: 100%;
}

.p-datatable-loader-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=10)";
  opacity: 0.1;
  z-index: 1;
}

.p-datatable-loader-content {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 2;
  margin-top: -1em;
  margin-left: -1em;
}
/* Reorder */
.p-datatable-reorder-indicator-up,
.p-datatable-reorder-indicator-down {
  position: absolute;
  display: none;
}

@media (max-width: 35em) {
  .p-datatable-reflow thead th,
  .p-datatable-reflow tfoot td {
    display: none !important;
  }

  .p-datatable-reflow .p-datatable-data > tr > td {
    text-align: left;
    display: block;
    border: 0 none;
    width: 100% !important;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    float: left;
    clear: left;
  }

  .p-datatable-reflow .p-datatable-data.p-widget-content {
    border: 0 none;
  }

  .p-datatable-reflow .p-datatable-data tr.p-widget-content {
    border-left: 0 none;
    border-right: 0 none;
  }

  .p-datatable-reflow .p-datatable-data td .p-column-title {
    padding: 0.4em;
    min-width: 30%;
    display: inline-block;
    margin: -0.4em 1em -0.4em -0.4em;
    font-weight: bold;
  }

  .p-datatable-reflow.p-datatable-scrollable
    .p-datatable-scrollable-body
    colgroup {
    display: block;
  }
}

.p-datascroller {
}

.p-datascroller .p-datascroller-header {
  text-align: center;
  padding: 0.5em 0.75em;
  border-bottom: 0 none;
}

.p-datascroller .p-datascroller-footer {
  text-align: center;
  padding: 0.25em 0.625em;
  border-top: 0px none;
}

.p-datascroller .p-datascroller-content {
  padding: 0.25em 0.625em;
}

.p-datascroller-inline .p-datascroller-content {
  overflow: auto;
}

.p-datascroller .p-datascroller-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.p-dialog {
  position: fixed;
  padding: 0;
}

.p-dialog .p-dialog-titlebar {
  padding: 0.5em 0.75em;
  position: relative;
  border: 0;
}

.p-dialog .p-dialog-content {
  position: relative;
  border: 0;
  padding: 0.5em 0.75em;
  background: none;
  overflow: auto;
  zoom: 1;
}

.p-dialog .p-dialog-buttonpane .p-dialog-buttonset {
  float: right;
}

.p-dialog .p-dialog-buttonpane button {
  margin: 0.5em 0.4em 0.5em 0;
  cursor: pointer;
  float: right;
}

.p-dialog .p-resizable-se {
  width: 14px;
  height: 14px;
  right: 3px;
  bottom: 3px;
}

.p-draggable .p-dialog-titlebar {
  cursor: move;
}

.p-dialog .p-dialog-titlebar-icon {
  text-decoration: none;
}

.p-dialog .p-dialog-titlebar-close {
  float: right;
  padding: 0.125em;
  cursor: pointer;
  border: 1px solid transparent;
}

.p-dialog .p-dialog-titlebar-close span {
  display: block;
  margin: 0;
}

.p-dialog-footer {
  padding: 1em;
  border-width: 1px 0 0 0;
  text-align: right;
}

.p-dialog-mask {
  position: fixed;
  width: 100%;
  height: 100%;
}
/* ConfirmDialog */
.p-confirmdialog {
  width: 30em;
}

.p-confirmdialog.p-dialog .p-dialog-content {
  padding: 1em 2em;
}

.p-confirmdialog .p-dialog-content .fa {
  font-size: 1.5em;
  vertical-align: middle;
  margin-right: 0.5em;
}

.p-confirmdialog .p-dialog-content .p-confirmdialog-message {
  vertical-align: middle;
}
/* Fluid */
.p-fluid .p-dialog-footer .p-button {
  width: auto;
}
/* RTL */
.p-rtl .p-dialog .p-dialog-titlebar-close {
  float: left;
}

.p-rtl .p-dialog .p-dialog-buttonpane button {
  text-align: right;
}

@media screen and (max-width: 40em) {
  .p-confirmdialog {
    width: 90%;
  }
}

.p-dropdown {
  display: inline-block;
  position: relative;
  cursor: pointer;
  vertical-align: middle;
}

.p-dropdown .p-dropdown-clear-icon {
  position: absolute;
  right: 2em;
  top: 50%;
  font-size: 0.75em;
  height: 1em;
  margin-top: -0.5em;
  right: 2.5em;
}

.p-dropdown .p-dropdown-trigger {
  border-right: none;
  border-top: none;
  border-bottom: none;
  cursor: pointer;
  width: 1.5em;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  padding: 0 0.25em;
}

.p-dropdown .p-dropdown-trigger .fa {
  margin-top: 0.3em;
  margin-left: -0.125em;
}

.p-dropdown .p-dropdown-label {
  display: block;
  border: none;
  white-space: nowrap;
  overflow: hidden;
  font-weight: normal;
  width: 100%;
  padding-right: 1.5em;
}

.p-dropdown .p-dropdown-item-empty,
.p-dropdown .p-dropdown-label-empty {
  text-indent: -9999px;
}

.p-dropdown.p-state-disabled .p-dropdown-trigger,
.p-dropdown.p-state-disabled .p-dropdown-label {
  cursor: default;
}

.p-dropdown label.p-dropdown-label {
  cursor: pointer;
}

.p-dropdown input.p-dropdown-label {
  cursor: default;
}

.p-dropdown .p-dropdown-panel {
  min-width: 100%;
}

.p-dropdown-panel {
  position: absolute;
  height: auto;
}

.p-dropdown-panel .p-dropdown-items-wrapper {
  overflow: auto;
}

.p-dropdown-panel .p-dropdown-item {
  font-weight: normal;
  border: 0 none;
  cursor: pointer;
  margin: 1px 0;
  padding: 0.125em 0.25em;
  text-align: left;
}

.p-dropdown-panel .p-dropdown-item-group {
  font-weight: bold;
}

.p-dropdown-panel .p-dropdown-list {
  padding: 0.4em;
  border: 0 none;
}

.p-dropdown-panel .p-dropdown-filter {
  width: 100%;
  box-sizing: border-box;
  padding-right: 1.5em;
}

.p-dropdown-panel .p-dropdown-filter-container {
  position: relative;
  margin: 0;
  padding: 0.4em;
  display: inline-block;
}

.p-dropdown-panel .p-dropdown-filter-container .fa {
  position: absolute;
  top: 0.8em;
  right: 1em;
}
/** Dropdown **/
.p-fluid .p-dropdown {
  width: 100%;
}

.p-fieldset,
.p-fieldset .p-fieldset-legend {
  padding: 0.5em 1em;
}

.p-fieldset-toggleable .p-fieldset-legend {
  padding: 0;
}

.p-fieldset-toggleable .p-fieldset-legend a {
  padding: 0.5em 1em;
  cursor: pointer;
  white-space: nowrap;
  display: block;
}

.p-fieldset .p-fieldset-toggler {
  margin-right: 0.1em;
  display: inline-block;
  vertical-align: middle;
}

.p-fieldset .p-fieldset-legend-text {
  vertical-align: middle;
}

.p-fieldset-content-wrapper-collapsed {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
}

.p-fieldset-content-wrapper-expanded {
  max-height: 1000px;
  transition: max-height 1s ease-in-out;
}

.p-fieldset-content-wrapper-expanding {
  overflow: hidden;
}
/*
 * FileUpload
 */
.p-fileupload-buttonbar .p-fileupload-choose.p-state-disabled input {
  cursor: default;
}

.p-fileupload-buttonbar {
  padding: 0.5em;
  border-bottom: 0 none;
}

.p-fileupload-buttonbar .p-button {
  vertical-align: middle;
  margin-right: 0.25em;
}

.p-fileupload-content {
  padding: 1em;
  position: relative;
  transition: border-color 0.3s;
}

.p-fileupload-content.p-fileupload-highlight {
  border-color: #156090;
}

.p-fileupload-files img {
  border: none;
}

.p-fileupload-files {
  display: table;
}

.p-fileupload-row {
  display: table-row;
}

.p-fileupload-row > div {
  display: table-cell;
  padding: 0.5em 1em;
  vertical-align: middle;
}

.p-fileupload-content .p-progressbar {
  width: 100%;
  position: absolute;
  top: 1px;
  left: 0;
  height: 0.25em;
  border: 0 none;
}

.p-fileupload-content .p-progressbar-value {
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  border: 0 none;
}
/* Simple */
.p-fileupload-choose {
  position: relative;
  overflow: hidden;
}

.p-fileupload-choose input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  direction: ltr;
  cursor: pointer;
}

.p-fileupload-choose.p-fileupload-choose-selected input[type="file"] {
  display: none;
}
/* p-fluid */
.p-fluid .p-fileupload .p-button {
  width: auto;
}

.p-fluid .p-fileupload-content .p-button-icon-only {
  width: 2em;
}

.p-inputswitch {
  display: inline-block;
  padding: 0;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  height: 1.5em;
}

.p-inputswitch .p-inputswitch-on,
.p-inputswitch .p-inputswitch-off {
  white-space: nowrap;
  display: inline-block;
  position: absolute;
  top: 0;
  width: auto;
  overflow: hidden;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  font-weight: bold;
  height: 100%;
  line-height: 1.5em;
}

.p-inputswitch .p-inputswitch-on {
  left: 0;
  border: 0 none;
}

.p-inputswitch .p-inputswitch-off {
  right: 0;
  text-align: right;
}

.p-inputswitch .p-inputswitch-on span,
.p-inputswitch .p-inputswitch-off span {
  display: inline-block;
  text-align: center;
  height: 100%;
  line-height: inherit;
}

.p-inputswitch .p-inputswitch-handle {
  display: block;
  width: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-top: 0 none;
  border-bottom: 0 none;
}

.p-growl {
  position: fixed;
  width: 20em;
}

.p-growl.p-growl-topright {
  top: 20px;
  right: 20px;
}

.p-growl.p-growl-topleft {
  top: 20px;
  left: 20px;
}

.p-growl.p-growl-bottomleft {
  bottom: 20px;
  left: 20px;
}

.p-growl.p-growl-bottomright {
  bottom: 20px;
  right: 20px;
}

.p-growl-item-container {
  position: relative;
  margin: 0 0 10px 0;
  opacity: 0.95;
  filter: alpha(opacity=95);
}

.p-growl-item {
  position: relative;
  display: block;
  padding: 0.5em 1em;
}

.p-growl-item p {
  padding: 0;
  margin: 0;
}

.p-growl-icon-close {
  position: absolute;
  top: 4px;
  right: 4px;
  cursor: pointer;
}

.p-growl-title {
  font-weight: bold;
  padding: 0 0 0.5em 0;
  display: block;
}

.p-growl-image {
  position: absolute;
  display: inline-block;
  left: 0.5em;
  top: 0.25em;
  padding: 0;
}

.p-growl-message {
  padding: 0 0 0.25em 0;
  margin-left: 2.5em;
}

.p-growl-message p {
  font-weight: normal;
}
/* Animations */
.p-growl-enter {
  opacity: 0.01;
}

.p-growl-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.p-growl-exit {
  max-height: 500px;
}

.p-growl-exit.p-growl-exit-active {
  max-height: 0;
  overflow: hidden;
  margin-bottom: 0;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1),
    margin-bottom 0.5s cubic-bezier(0, 1, 0, 1);
}
/* Deprecated Grid CSS */
.p-grid {
  clear: both;
  padding: 0;
  margin: 0;
}

.p-grid:before,
.p-grid:after {
  content: "";
  display: table;
}

.p-grid:after {
  clear: both;
}

.p-grid .p-grid-row {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  clear: both;
}

.p-grid-row:after {
  clear: both;
  content: "";
  display: table;
}

.p-grid-col-1,
.p-grid-col-2,
.p-grid-col-3,
.p-grid-col-4,
.p-grid-col-5,
.p-grid-col-6,
.p-grid-col-7,
.p-grid-col-8,
.p-grid-col-9,
.p-grid-col-10,
.p-grid-col-11,
.p-grid-col-12 {
  float: left;
  box-sizing: border-box;
}

.p-grid-col-1 {
  width: 8.33333%;
}

.p-grid-col-2 {
  width: 16.66666%;
}

.p-grid-col-3 {
  width: 25%;
}

.p-grid-col-4 {
  width: 33.33333%;
}

.p-grid-col-5 {
  width: 41.66666%;
}

.p-grid-col-6 {
  width: 50%;
}

.p-grid-col-7 {
  width: 58.33333%;
}

.p-grid-col-8 {
  width: 66.66666%;
}

.p-grid-col-9 {
  width: 75%;
}

.p-grid-col-10 {
  width: 83.33333%;
}

.p-grid-col-11 {
  width: 91.66666%;
}

.p-grid-col-12 {
  width: 100%;
}

@media (min-width: 480px) {
  .p-grid-fixed {
    width: 480;
  }
}

@media (min-width: 768px) {
  .p-grid-fixed {
    width: 768px;
  }
}

@media (min-width: 960px) {
  .p-grid-fixed {
    width: 960;
  }
}

@media (min-width: 1024px) {
  .p-grid-fixed {
    width: 1024px;
  }
}
/* Responsive */
@media (max-width: 640px) {
  .p-grid-responsive .p-grid-row {
    display: block;
  }

  .p-grid-responsive .p-grid-col-1,
  .p-grid-responsive .p-grid-col-2,
  .p-grid-responsive .p-grid-col-3,
  .p-grid-responsive .p-grid-col-4,
  .p-grid-responsive .p-grid-col-5,
  .p-grid-responsive .p-grid-col-6,
  .p-grid-responsive .p-grid-col-7,
  .p-grid-responsive .p-grid-col-8,
  .p-grid-responsive .p-grid-col-9,
  .p-grid-responsive .p-grid-col-10,
  .p-grid-responsive .p-grid-col-11,
  .p-grid-responsive .p-grid-col-12 {
    width: 100%;
    float: none;
  }
}

.p-grid.p-grid-pad > .p-grid-row > div {
  padding: 0.25em 0.5em;
}
/* Responsive */
@media (max-width: 640px) {
  .p-grid-responsive .p-grid-row {
    display: block;
  }

  .p-grid-responsive .p-grid-col-1,
  .p-grid-responsive .p-grid-col-2,
  .p-grid-responsive .p-grid-col-3,
  .p-grid-responsive .p-grid-col-4,
  .p-grid-responsive .p-grid-col-5,
  .p-grid-responsive .p-grid-col-6,
  .p-grid-responsive .p-grid-col-7,
  .p-grid-responsive .p-grid-col-8,
  .p-grid-responsive .p-grid-col-9,
  .p-grid-responsive .p-grid-col-10,
  .p-grid-responsive .p-grid-col-11,
  .p-grid-responsive .p-grid-col-12 {
    width: 100%;
    float: none;
  }
}
/* New Grid CSS */
.p-g {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
}

.p-g:after {
  clear: both;
  content: "";
  display: table;
}

.p-g-1,
.p-g-2,
.p-g-3,
.p-g-4,
.p-g-5,
.p-g-6,
.p-g-7,
.p-g-8,
.p-g-9,
.p-g-10,
.p-g-11,
.p-g-12 {
  float: left;
  box-sizing: border-box;
  padding: 0.5em;
}

.p-g-1 {
  width: 8.3333%;
}

.p-g-2 {
  width: 16.6667%;
}

.p-g-3 {
  width: 25%;
}

.p-g-4 {
  width: 33.3333%;
}

.p-g-5 {
  width: 41.6667%;
}

.p-g-6 {
  width: 50%;
}

.p-g-7 {
  width: 58.3333%;
}

.p-g-8 {
  width: 66.6667%;
}

.p-g-9 {
  width: 75%;
}

.p-g-10 {
  width: 83.3333%;
}

.p-g-11 {
  width: 91.6667%;
}

.p-g-12 {
  width: 100%;
}

@media screen and (max-width: 40em) {
  .p-sm-1,
  .p-sm-2,
  .p-sm-3,
  .p-sm-4,
  .p-sm-5,
  .p-sm-6,
  .p-sm-7,
  .p-sm-8,
  .p-sm-9,
  .p-sm-10,
  .p-sm-11,
  .p-sm-12 {
    padding: 0.5em;
  }

  .p-sm-1 {
    width: 8.3333%;
  }

  .p-sm-2 {
    width: 16.6667%;
  }

  .p-sm-3 {
    width: 25%;
  }

  .p-sm-4 {
    width: 33.3333%;
  }

  .p-sm-5 {
    width: 41.6667%;
  }

  .p-sm-6 {
    width: 50%;
  }

  .p-sm-7 {
    width: 58.3333%;
  }

  .p-sm-8 {
    width: 66.6667%;
  }

  .p-sm-9 {
    width: 75%;
  }

  .p-sm-10 {
    width: 83.3333%;
  }

  .p-sm-11 {
    width: 91.6667%;
  }

  .p-sm-12 {
    width: 100%;
  }
}

@media screen and (min-width: 40.063em) {
  .p-md-1,
  .p-md-2,
  .p-md-3,
  .p-md-4,
  .p-md-5,
  .p-md-6,
  .p-md-7,
  .p-md-8,
  .p-md-9,
  .p-md-10,
  .p-md-11,
  .p-md-12 {
    padding: 0.5em;
  }

  .p-md-1 {
    width: 8.3333%;
  }

  .p-md-2 {
    width: 16.6667%;
  }

  .p-md-3 {
    width: 25%;
  }

  .p-md-4 {
    width: 33.3333%;
  }

  .p-md-5 {
    width: 41.6667%;
  }

  .p-md-6 {
    width: 50%;
  }

  .p-md-7 {
    width: 58.3333%;
  }

  .p-md-8 {
    width: 66.6667%;
  }

  .p-md-9 {
    width: 75%;
  }

  .p-md-10 {
    width: 83.3333%;
  }

  .p-md-11 {
    width: 91.6667%;
  }

  .p-md-12 {
    width: 100%;
  }
}

@media screen and (min-width: 64.063em) {
  .p-lg-1,
  .p-lg-2,
  .p-lg-3,
  .p-lg-4,
  .p-lg-5,
  .p-lg-6,
  .p-lg-7,
  .p-lg-8,
  .p-lg-9,
  .p-lg-10,
  .p-lg-11,
  .p-lg-12 {
    padding: 0.5em;
  }

  .p-lg-1 {
    width: 8.3333%;
  }

  .p-lg-2 {
    width: 16.6667%;
  }

  .p-lg-3 {
    width: 25%;
  }

  .p-lg-4 {
    width: 33.3333%;
  }

  .p-lg-5 {
    width: 41.6667%;
  }

  .p-lg-6 {
    width: 50%;
  }

  .p-lg-7 {
    width: 58.3333%;
  }

  .p-lg-8 {
    width: 66.6667%;
  }

  .p-lg-9 {
    width: 75%;
  }

  .p-lg-10 {
    width: 83.3333%;
  }

  .p-lg-11 {
    width: 91.6667%;
  }

  .p-lg-12 {
    width: 100%;
  }
}

@media screen and (min-width: 90.063em) {
  .p-xl-1,
  .p-xl-2,
  .p-xl-3,
  .p-xl-4,
  .p-xl-5,
  .p-xl-6,
  .p-xl-7,
  .p-xl-8,
  .p-xl-9,
  .p-xl-10,
  .p-xl-11,
  .p-xl-12 {
    padding: 0.5em;
  }

  .p-xl-1 {
    width: 8.3333%;
  }

  .p-xl-2 {
    width: 16.6667%;
  }

  .p-xl-3 {
    width: 25%;
  }

  .p-xl-4 {
    width: 33.3333%;
  }

  .p-xl-5 {
    width: 41.6667%;
  }

  .p-xl-6 {
    width: 50%;
  }

  .p-xl-7 {
    width: 58.3333%;
  }

  .p-xl-8 {
    width: 66.6667%;
  }

  .p-xl-9 {
    width: 75%;
  }

  .p-xl-10 {
    width: 83.3333%;
  }

  .p-xl-11 {
    width: 91.6667%;
  }

  .p-xl-12 {
    width: 100%;
  }
}

.p-g-nopad {
  padding: 0;
}

.p-inputtext {
  margin: 0;
  outline: medium none;
  padding: 0.25em;
  font-weight: normal;
}

.p-widget-header .p-inputtext,
.p-widget-content .p-inputtext {
  font-weight: normal;
}

.p-fluid .p-inputtext {
  width: 100%;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.p-inputgroup {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}

.p-inputgroup .p-inputgroup-addon {
  display: inline-block;
  text-align: center;
  min-width: 1.5em;
  padding: 0.25em;
  border-width: 1px;
  border-style: solid;
}

.p-inputgroup .p-inputgroup-addon + .p-inputgroup-addon {
  border-left: 0 none;
}

.p-inputgroup .p-inputtext {
  padding-left: 0.5em;
}

.p-inputgroup .p-inputtext:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0 none;
}

.p-inputgroup .p-inputtext:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0 none;
}

.p-inputgroup .p-button {
  margin-right: 0;
  border-radius: 0;
}

.p-fluid .p-inputgroup .p-button {
  width: auto;
}

.p-fluid .p-inputgroup .p-inputtext {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.p-inputgroup .p-chkbox,
.p-inputgroup .p-radiobutton {
  margin-right: 0;
  vertical-align: bottom;
}
/* Floating Label */
.p-float-label {
  display: block;
  position: relative;
}

.p-float-label label {
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 0.25em;
  top: 50%;
  margin-top: -0.5em;
  transition: 0.3s ease all;
  -moz-transition: 0.3s ease all;
  -webkit-transition: 0.3s ease all;
  color: #898989;
  line-height: 1;
}

.p-float-label input:focus ~ label,
.p-float-label input.p-state-filled ~ label,
.p-float-label .p-inputwrapper-focus ~ label,
.p-float-label .p-inputwrapper-filled ~ label {
  top: -0.75em;
  font-size: 12px;
}

.p-float-label .input:-webkit-autofill ~ label {
  top: -20px;
  font-size: 12px;
}

.p-inputtextarea-resizable {
  overflow: hidden;
  resize: none;
}

.p-fluid .p-inputtextarea {
  width: 100%;
}

.p-lightbox {
  position: fixed;
}

.p-lightbox-content-wrapper {
  position: relative;
}

.p-lightbox-content {
  position: relative;
  margin: 0;
  padding: 0;
  background-color: #000000;
  -webkit-transition-property: width, height;
  -moz-transition-property: width, height;
  -ms-transition-property: width, height;
  -o-transition-property: width, height;
  transition-property: width, height;
}

.p-lightbox-nav-right,
.p-lightbox-nav-left {
  position: absolute;
  top: 50%;
  cursor: pointer;
}

.p-lightbox-nav-left {
  left: 0;
}

.p-lightbox-nav-right {
  right: 0;
}

.p-lightbox-loading .p-lightbox-content {
  /* background: url("./images/loading.gif") #000000 center center no-repeat; */
}

.p-lightbox-caption {
  padding: 0.2em 0.4em;
  display: none;
}

.p-lightbox-caption-text {
  margin: 0.3em 0 0.1em 0;
  float: left;
}

.p-lightbox-close {
  float: right;
  margin: 0;
  padding: 0.125em;
}

.p-lightbox-close.p-state-hover {
  padding: 0;
}

.p-lightbox-nav-left,
.p-lightbox-nav-right {
  opacity: 0.5;
}

.p-lightbox-nav-left:hover,
.p-lightbox-nav-right:hover {
  opacity: 1;
}

.p-listbox {
  padding: 0.25em;
  width: 10em;
}

.p-listbox .p-listbox-list-wrapper {
  overflow: auto;
}

.p-listbox .p-listbox-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.p-listbox .p-listbox-item {
  padding: 0.25em;
  border: 0 none;
  cursor: pointer;
  font-weight: normal;
  margin-bottom: 1px;
}

.p-listbox .p-listbox-item > span {
  vertical-align: middle;
}

.p-listbox .p-listbox-item:last-child {
  margin-bottom: 0;
}

.p-listbox.p-state-disabled .p-listbox-item {
  cursor: default;
}

.p-listbox-header {
  margin-bottom: 0.3em;
  padding: 0.125em 0.2em;
  position: relative;
}

.p-listbox-header .p-chkbox {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.p-listbox-header .p-listbox-filter-container {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  width: 100%;
}

.p-listbox-header.p-listbox-header-w-checkbox .p-listbox-filter-container {
  width: calc(100% - 2em);
}

.p-listbox-header .p-listbox-filter-container .fa {
  position: absolute;
  top: 0.25em;
  left: 0.25em;
}

.p-listbox-header .p-inputtext {
  padding: 0.125em 0.125em 0.125em 1.25em;
  width: 100%;
}

.p-menu {
  width: 12.5em;
  padding: 0.25em;
  position: relative;
}

.p-menu-separator {
  border-width: 1px 0 0 0;
}

.p-menu.p-menu-dynamic {
  position: absolute;
  display: none;
  z-index: 100000;
}

.p-menu-list {
  position: static;
}

.p-menu .p-menu-list .p-menuitem {
  border: none;
}

.p-menu .p-menu-list .p-widget-header {
  clear: both;
  float: left;
  width: 100%;
  margin: 0.125em 0;
  padding: 0.25em 0.5em;
}

.p-menu .p-menuitem-parent,
.p-menu .p-menuitem {
  width: 100%;
  clear: both;
  margin: 0.125em 0;
  padding: 0;
}

.p-menu .p-menuitem-link {
  display: block;
  width: 100%;
  text-decoration: none;
  font-weight: normal;
  border: 1px solid transparent;
  line-height: 1em;
  padding: 0.25em;
  cursor: pointer;
}

.p-menu .p-menuitem-link .p-menuitem-icon {
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.25em;
}

.p-menu .p-menuitem-text {
  vertical-align: middle;
}

.p-menu .p-widget-header h1,
.p-menu .p-widget-header h2,
.p-menu .p-widget-header h3,
.p-menu .p-widget-header h4,
.p-menu .p-widget-header h5,
.p-menu .p-widget-header h6 {
  font-size: 1em;
  margin: 0 auto;
}
/* Tiered Menu */
.p-menu .p-menu-parent .p-menu-child {
  display: none;
  width: 12.5em;
  padding: 0.25em;
  position: absolute;
  margin: 0;
  text-decoration: none;
  list-style: none;
}

.p-menu .p-menu-parent {
  position: relative;
}

.p-menu .p-menu-parent .p-submenu-icon {
  float: right;
  margin-right: -0.25em;
}
/** MenuButton **/
.p-menubutton {
  padding: 0;
}

.p-menubutton .p-button {
  margin: 0;
}
/** Menubar **/
.p-menu.p-menubar .p-menubar-root-list > li > a > .p-submenu-icon {
  float: none;
}

.p-menubar {
  width: auto;
}

.p-menubar .p-menubar-root-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.p-menubar .p-menubar-root-list > .p-menuitem {
  display: inline-block;
  width: auto;
}

.p-menubar:not(.p-megamenu-vertical) .p-menubar-root-list > .p-menu-separator {
  display: inline-block;
  border-width: 0 0 0 1px;
  width: 1px;
  text-indent: -9999999px;
}

.p-menubar:not(.p-megamenu-vertical)
  .p-menubar-root-list
  > .p-menu-separator:before {
  content: "p-menu-separator";
}

.p-menubar .p-menu-child .p-menuitem {
  width: 100%;
}

.p-menubar .p-menuitem.p-menuitem-custom {
  float: right;
  margin-top: 0.25em;
}

.p-menubar .p-menubar-options {
  float: right;
}
/** SlideMenu **/
.p-slidemenu .p-slidemenu-wrapper {
  position: relative;
}

.p-slidemenu .p-slidemenu-content {
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}

.p-slidemenu .p-menu-list {
  position: absolute;
  top: 0;
}

.p-slidemenu .p-menu-parent {
  position: static;
}

.p-slidemenu .p-menu-child {
  box-shadow: none;
  border: 0 none;
  background: none repeat scroll 0 0 transparent;
}

.p-slidemenu-backward {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0.2em;
  cursor: pointer;
  display: none;
}

.p-slidemenu-backward .fa {
  vertical-align: middle;
}

.p-slidemenu-backward span {
  vertical-align: middle;
}

.p-slidemenu .p-slidemenuitem-active > ul {
  display: block !important;
}
/** MegaMenu **/
.p-megamenu .p-g {
  flex-wrap: nowrap;
}

.p-megamenu .p-megamenu-panel.p-menu-child {
  width: auto;
}

.p-megamenu .p-megamenu-panel .p-menu-list {
  width: 12.5em;
}

.p-megamenu-vertical {
  width: 12.5em;
}

.p-megamenu-vertical .p-menuitem-link,
.p-megamenu-vertical .p-menu-list .p-menuitem {
  width: 100%;
  box-sizing: border-box;
}

.p-megamenu-vertical
  > .p-menubar-root-list
  > .p-menuitem
  > .p-menuitem-link
  > .p-submenu-icon {
  float: right;
}
/** PanelMenu **/
.p-panelmenu {
  width: auto;
}

.p-panelmenu .p-panelmenu-panel {
  padding: 0;
  margin: 0;
}

.p-panelmenu .p-panelmenu-header {
  cursor: pointer;
  position: relative;
  margin: -1px 0 0 0;
  zoom: 1;
}

.p-panelmenu .p-panelmenu-header a {
  display: block;
  padding: 0.25em 0.5em;
  text-decoration: none;
}

.p-panelmenu span {
  vertical-align: middle;
}

.p-panelmenu .fa {
  width: 1em;
  text-align: center;
  vertical-align: middle;
  margin-right: 0.25em;
}

.p-panelmenu .p-menuitem-text {
  margin-left: 0.125em;
}

.p-panelmenu span {
  vertical-align: middle;
}

.p-panelmenu .p-panelmenu-content {
  padding: 0.2em 0;
  border-top: 0;
  overflow: auto;
  zoom: 1;
  outline: none;
  margin-bottom: 1px;
}

.p-panelmenu .p-panelmenu-content .p-menu-parent {
  overflow: hidden;
}

.p-panelmenu .p-panelmenu-content-wrapper {
  box-sizing: border-box;
}

.p-panelmenu .p-panelmenu-content-wrapper-overflown {
  overflow: hidden;
}

.p-panelmenu .p-panelmenu-header.p-state-disabled,
.p-panelmenu .p-panelmenu-header.p-state-disabled a {
  cursor: default;
}

.p-panelmenu .p-menu-list {
  position: static;
}

.p-panelmenu .p-menuitem {
  margin: 1px 0;
  padding: 0;
}

.p-panelmenu .p-menu-separator {
  width: 95%;
  margin: 0 auto;
}

.p-panelmenu .p-menuitem-link {
  display: block;
  text-decoration: none;
  font-weight: normal;
  border: 1px solid transparent;
  line-height: 1em;
  cursor: pointer;
  position: relative;
  padding: 0.25em 0.5em;
}

.p-panelmenu .p-menu-parent .p-menu-list {
  margin-left: 1.5em;
}
/** MegaMenu and TieredMenus **/
.p-menuitem-active > .p-submenu > ul,
.p-menuitem-active > .p-megamenu-panel {
  display: block !important;
}

.p-menuitem-outline {
  outline: 1px dotted;
  z-index: 1;
}
/** Fluid **/
.p-fluid .p-menu {
  width: 100%;
}

.p-message {
  border: 1px solid;
  margin: 0px 0.25em;
  padding: 0.25em 0.5em;
  display: inline-block;
}

.p-message .fa {
  margin-right: 0.25em;
}

.p-message.p-message-icon-only .fa {
  margin-right: 0;
}

.p-fluid .p-message {
  display: block;
}

.p-message-success {
  color: #ffffff;
  background-color: #4caf50;
  border-color: #4caf50;
}

.p-message-info {
  color: #ffffff;
  background-color: #2196f3;
  border-color: #2196f3;
}

.p-message-warn {
  color: #ffffff;
  background-color: #ffb300;
  border-color: #ffb300;
}

.p-message-error {
  color: #ffffff;
  background-color: #f44336;
  border-color: #f44336;
}

.p-messages {
  margin-bottom: 0.5em;
  position: relative;
}

.p-messages .p-messages-wrapper {
  padding: 1em;
}

.p-messages-icon {
  display: inline-block;
  padding: 0;
  vertical-align: middle;
}

.p-messages-summary {
  font-weight: bold;
  margin-left: 0.25em;
}

.p-messages-detail {
  margin-left: 0.25em;
}

.p-messages-success {
  color: #ffffff;
  background-color: #4caf50;
  border-color: #4caf50;
}

.p-messages-info {
  color: #ffffff;
  background-color: #2196f3;
  border-color: #2196f3;
}

.p-messages-warn {
  color: #ffffff;
  background-color: #ffb300;
  border-color: #ffb300;
}

.p-messages-error {
  color: #ffffff;
  background-color: #f44336;
  border-color: #f44336;
}

.p-messages ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: inline-block;
  vertical-align: middle;
}

.p-messages.p-messages-noicon ul {
  margin: 0 1.5em 0 0;
}

.p-messages .p-messages-close {
  color: #ffffff;
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 5px;
}
/* Animations */
.p-messages-enter {
  opacity: 0.01;
}

.p-messages-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.p-messages-exit {
  max-height: 500px;
}

.p-messages-exit.p-messages-exit-active {
  max-height: 0;
  overflow: hidden;
  margin-bottom: 0;
  transition: max-height 500ms cubic-bezier(0, 1, 0, 1),
    margin-bottom 500ms cubic-bezier(0, 1, 0, 1);
}
/** MultiSelect **/
.p-multiselect {
  display: inline-block;
  position: relative;
  width: auto;
  cursor: pointer;
}

.p-multiselect .p-multiselect-trigger {
  border-right: none;
  border-top: none;
  border-bottom: none;
  cursor: pointer;
  width: 1.5em;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  padding: 0 0.25em;
}

.p-multiselect .p-multiselect-trigger .fa {
  margin-top: 0.4em;
  margin-left: -0.125em;
}

.p-multiselect .p-multiselect-label-container {
  overflow: hidden;
}

.p-multiselect .p-multiselect-label {
  display: block;
  padding: 0.25em 2em 0.25em 0.25em;
  width: auto;
  border: none;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
}

.p-multiselect.p-state-disabled .p-multiselect-trigger,
.p-multiselect.p-state-disabled .p-multiselect-label {
  cursor: auto;
}

.p-multiselect-panel {
  padding: 0.2em;
  position: absolute;
  min-width: 10em;
}

.p-multiselect .p-multiselect-panel {
  min-width: 100%;
}

.p-multiselect-panel .p-multiselect-items-wrapper {
  overflow: auto;
  position: relative;
  padding: 0.2em 0;
}

.p-multiselect-panel .p-multiselect-list {
  border: 0 none;
}

.p-multiselect-panel .p-multiselect-item {
  border: 0 none;
  cursor: pointer;
  font-weight: normal;
  margin: 1px 0;
  padding: 0.125em 0.25em;
  text-align: left;
  white-space: nowrap;
  display: block;
  position: relative;
}

.p-multiselect-panel .p-multiselect-item .p-chkbox {
  display: inline-block;
  vertical-align: middle;
}

.p-multiselect-panel .p-multiselect-item label {
  display: inline-block;
  vertical-align: middle;
}

.p-multiselect-header {
  margin-bottom: 0.3em;
  padding: 0.25em;
  position: relative;
  text-align: left;
}

.p-multiselect-header .p-chkbox {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.p-multiselect-header .p-multiselect-filter-container {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 65%;
}

.p-multiselect-header .p-multiselect-filter-container .fa {
  position: absolute;
  top: 0.25em;
  left: 0.125em;
}

.p-multiselect-header .p-inputtext {
  padding: 0.125em 0.125em 0.125em 1.25em;
  width: 100%;
}

.p-multiselect-header .p-multiselect-close {
  position: absolute;
  right: 0.375em;
  top: 0.375em;
  display: block;
  font-size: 1em;
  border: 0 none;
}

.p-multiselect-header a.p-multiselect-all,
.p-multiselect-header a.p-multiselect-none {
  float: left;
  margin-right: 10px;
  display: block;
}

.p-multiselect-header .p-multiselect-close.p-state-hover {
  padding: 0px;
}

.p-fluid .p-multiselect {
  width: 100%;
  box-sizing: border-box;
}

.p-tieredmenu-item {
  left: 100%;
  top: 0px;
}

.p-orderlist {
  display: table;
}

.p-orderlist .p-orderlist-controls {
  height: 12.5em;
  padding: 0 0.25em;
  vertical-align: middle;
  display: table-cell;
}

.p-orderlist .p-orderlist-controls .p-button {
  display: block;
  margin-bottom: 0.25em;
}

.p-orderlist .p-orderlist-container {
  display: table-cell;
  vertical-align: top;
}

.p-orderlist .p-orderlist-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: auto;
  height: 12.5em;
  width: 12.5em;
}

.p-orderlist .p-orderlist-caption {
  text-align: center;
  padding: 0.5em 0.75em;
  border-bottom: 0 none;
}

.p-orderlist .p-orderlist-list .p-orderlist-item {
  margin: 1px;
  padding: 0.125em;
  cursor: pointer;
  border: 0 none;
  font-weight: inherit;
}

.p-orderlist .p-orderlist-filter-container {
  position: relative;
  width: 100%;
  padding: 0.5em 0.6em;
  border-bottom: 0 none;
}

.p-orderlist .p-orderlist-filter-container .p-inputtext {
  text-indent: 1.1em;
  width: 100%;
}

.p-orderlist .p-orderlist-filter-container .fa {
  position: absolute;
  top: 50%;
  left: 1em;
  margin-top: -0.6em;
}

.p-orderlist.p-state-disabled .p-orderlist-item,
.p-orderlist.p-state-disabled .p-button {
  cursor: default;
}

.p-orderlist.p-state-disabled .p-orderlist-list {
  overflow: hidden;
}
/* Responsive */
.p-orderlist.p-orderlist-responsive {
  width: 100%;
}

.p-orderlist.p-orderlist-responsive .p-orderlist-controls {
  width: 16.66666%;
  padding-right: 0.5em;
}

.p-orderlist.p-orderlist-responsive .p-orderlist-list-container {
  width: 83.33333%;
}

.p-orderlist.p-orderlist-responsive .p-orderlist-list,
.p-orderlist.p-orderlist-responsive .p-orderlist-caption {
  width: 100%;
}

.p-orderlist.p-orderlist-responsive .p-orderlist-controls > .p-button {
  width: 100%;
}

.p-orderlist .p-orderlist-droppoint {
  height: 6px;
  list-style-type: none;
}

@media (max-width: 40em) {
  .p-orderlist.p-orderlist-responsive .p-orderlist-controls {
    text-align: center;
    width: 100%;
    display: inline-block;
    height: auto;
  }

  .p-orderlist.p-orderlist-responsive .p-orderlist-controls .p-button {
    display: inline;
    width: 20%;
    display: inline-block;
  }

  .p-orderlist.p-orderlist-responsive .p-orderlist-list-container {
    width: 100%;
  }
}

.p-organizationchart .p-organizationchart-table {
  border-spacing: 0;
  border-collapse: separate;
}

.p-organizationchart .p-organizationchart-table > tbody > tr > td {
  text-align: center;
  vertical-align: top;
  padding: 0;
  padding: 0 0.75em;
}

.p-organizationchart .p-organizationchart-node-content {
  padding: 0.5em 0.75em;
  display: inline-block;
  position: relative;
}

.p-organizationchart .p-organizationchart-node-content .p-node-toggler {
  position: absolute;
  bottom: -9px;
  margin-left: -8px;
  z-index: 2;
  left: 50%;
}

.p-organizationchart .p-organizationchart-line-down {
  margin: 0 auto;
  height: 20px;
  width: 1px;
  float: none;
}

.p-organizationchart .p-organizationchart-line-right {
  float: none;
  border-radius: 0px;
}

.p-organizationchart .p-organizationchart-line-left {
  float: none;
  border-radius: 0;
}

.p-organizationchart
  .p-organizationchart-node-content.p-organizationchart-selectable-node {
  cursor: pointer;
}

.p-overlaypanel {
  padding: 0;
  margin: 0;
  position: absolute;
  display: none;
}

.p-overlaypanel-content {
  padding: 0.5em 1em;
}

.p-overlaypanel-close {
  position: absolute;
  top: -0.5em;
  right: -0.5em;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
}

.p-paginator {
  margin: 0;
  text-align: center;
  padding: 0.125em;
}

.p-paginator .p-paginator-top {
  border-bottom: 0 none;
}

.p-paginator .p-paginator-bottom {
  border-top: 0 none;
}

.p-paginator .p-paginator-left-content {
  float: left;
}

.p-paginator .p-paginator-right-content {
  float: right;
}

.p-paginator .p-paginator-page,
.p-paginator .p-paginator-pages,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last,
.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-current {
  display: inline-block;
  padding: 0.125em 0.375em;
  zoom: 1;
  margin-left: 0.063em;
  margin-right: 0.063em;
  text-decoration: none;
  vertical-align: middle;
}

.p-paginator .p-paginator-page,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last,
.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev {
  cursor: pointer;
}

.p-paginator .p-paginator-current,
.p-paginator .p-paginator-rpp-options {
  margin-left: 1em;
  margin-right: 1em;
  background-image: none;
}

.p-paginator .p-paginator-jtp-select option,
.p-paginator .p-paginator-rpp-options option {
  background-image: none;
  border: 0 none;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

.p-paginator a.p-state-disabled {
  outline: 0 none;
}

.p-paginator .p-dropdown {
  min-width: 4em;
  margin-left: 0.375em;
}

.p-panel {
  padding: 0.2em;
}

.p-panel .p-panel-titlebar {
  padding: 0.5em 0.75em;
}

.p-panel .p-panel-titlebar-icon {
  float: right;
  cursor: pointer;
}

.p-panel .p-panel-titlebar-icon {
  margin-left: 0.2em;
  margin-top: -0.1em;
}

.p-panel .p-panel-content {
  border: 0;
  background: none;
  padding: 0.5em 0.75em;
}

.p-panel .p-panel-footer {
  border-width: 1px 0 0;
  padding: 0.25em 0.5em;
  text-align: left;
}

.p-panel-content-wrapper-collapsed {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
}

.p-panel-content-wrapper-expanded {
  max-height: 1000px;
  transition: max-height 1s ease-in-out;
}

.p-panel-content-wrapper-expanding {
  overflow: hidden;
}

.p-password-panel {
  padding: 0.25em 0.5em;
  width: 10em;
  margin-top: 2px;
}

.p-password-panel .p-password-meter {
  height: 10px;
  /* background: transparent url("./images/password-meter.png") no-repeat left top; */
  padding: 0;
  margin: 0;
}

.p-password-info {
  margin-top: 0.25em;
}

.p-password-panel-overlay {
  position: absolute;
}

.p-picklist > div {
  float: left;
}

.p-picklist .p-picklist-buttons {
  height: 12.5em;
  padding: 0 0.25em;
}

.p-picklist .p-picklist-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: auto;
  height: 12.5em;
  width: 12.5em;
}

.p-picklist .p-picklist-list li {
  margin: 1px;
  padding: 0.125em;
}

.p-picklist .p-button {
  display: block;
  margin-bottom: 0.25em;
}

.p-picklist .p-button-text-icon-left {
  width: 100%;
}

.p-picklist .p-picklist-item {
  cursor: pointer;
  border: 0 none;
  font-weight: inherit;
}

.p-picklist .p-picklist-caption {
  text-align: center;
  padding: 0.5em 0.75em;
  border-bottom: 0 none;
}

.p-picklist table {
  width: 100%;
  border-collapse: collapse;
}

.p-picklist .p-picklist-filter-container {
  position: relative;
  width: 100%;
  padding: 0.5em 0.6em;
  border-bottom: 0 none;
}

.p-picklist .p-picklist-filter-container .p-picklist-filter {
  text-indent: 1.1em;
  width: 100%;
}

.p-picklist .p-picklist-filter-container .fa {
  position: absolute;
  top: 50%;
  left: 1em;
  margin-top: -0.6em;
}

.p-picklist {
  display: table;
}

.p-picklist > div {
  float: none;
  display: table-cell;
  vertical-align: top;
}

.p-picklist .p-picklist-buttons {
  vertical-align: middle;
}
/* Vertical */
.p-picklist.p-picklist-vertical {
  display: table;
}

.p-picklist.p-picklist-vertical > div {
  float: none;
  display: table-row;
  vertical-align: top;
}

.p-picklist.p-picklist-vertical .p-picklist-buttons {
  text-align: center;
  height: auto;
}

.p-picklist.p-picklist-vertical .p-picklist-buttons .p-button {
  display: inline-block;
}

.p-picklist.p-picklist-vertical .p-button {
  margin-top: 0.25em;
}

.p-picklist-outline {
  outline: 1px dotted black;
  z-index: 1;
}

.p-picklist .p-picklist-droppoint {
  height: 6px;
  list-style-type: none;
}

.p-picklist .p-picklist-list .p-picklist-droppoint-empty {
  height: 100%;
  list-style-type: none;
}

.p-picklist-list.p-picklist-source,
.p-picklist-list.p-picklist-target {
  outline: none;
}
/* Responsive */
.p-picklist.p-picklist-responsive * {
  box-sizing: border-box;
}

.p-picklist.p-picklist-responsive {
  width: 100%;
}

.p-picklist.p-picklist-responsive .p-picklist-listwrapper {
  width: 35%;
}

.p-picklist.p-picklist-responsive
  .p-picklist-listwrapper.p-picklist-listwrapper-nocontrols {
  width: 45%;
}

.p-picklist.p-picklist-responsive .p-picklist-buttons {
  width: 10%;
}

.p-picklist.p-picklist-responsive .p-picklist-buttons button {
  width: 100%;
}

.p-picklist.p-picklist-responsive .p-picklist-list {
  width: auto;
}
/* Responsive */
@media (max-width: 40em) {
  .p-picklist.p-picklist-responsive {
    display: block;
  }

  .p-picklist.p-picklist-responsive > div {
    display: block;
    width: 100% !important;
  }

  .p-picklist.p-picklist-responsive .p-picklist-buttons {
    text-align: center;
    height: auto;
    padding: 0.4em 0;
  }

  .p-picklist.p-picklist-responsive .p-picklist-buttons button {
    display: inline;
    width: 20%;
    margin-bottom: 0;
  }

  .p-picklist.p-picklist-responsive
    .p-picklist-source-controls.p-picklist-buttons {
    padding-bottom: 0.4em;
  }

  .p-picklist.p-picklist-responsive
    .p-picklist-target-controls.p-picklist-buttons {
    padding-top: 0.4em;
  }

  .p-picklist.p-picklist-responsive .p-picklist-buttons .fa-angle-right:before {
    content: "\f107";
  }

  .p-picklist.p-picklist-responsive
    .p-picklist-buttons
    .fa-angle-double-right:before {
    content: "\f103";
  }

  .p-picklist.p-picklist-responsive .p-picklist-buttons .fa-angle-left:before {
    content: "\f106";
  }

  .p-picklist.p-picklist-responsive
    .p-picklist-buttons
    .fa-angle-double-left:before {
    content: "\f102";
  }
}

.p-progressbar {
  height: 1.2em;
  text-align: left;
  position: relative;
  overflow: hidden;
}

.p-progressbar-determinate .p-progressbar-value {
  height: 100%;
  width: 0%;
  position: absolute;
  display: none;
  border: 0 none;
}

.p-progressbar-determinate .p-progressbar-value-animate {
  -webkit-transition: width 1s ease-in-out;
  -moz-transition: width 1s ease-in-out;
  -o-transition: width 1s ease-in-out;
  transition: width 1s ease-in-out;
}

.p-progressbar-determinate .p-progressbar-label {
  text-align: center;
  height: 100%;
  width: 100%;
  position: absolute;
  display: none;
  font-weight: bold;
}

.p-progressbar.p-progressbar-indeterminate {
  border-width: 0;
  overflow: visible;
  margin-top: 1.5em;
}

.p-progressbar-indeterminate .p-progressbar-indeterminate-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  border: 1px solid;
  border-color: inherit;
  border-radius: inherit;
}

.p-progressbar-indeterminate .p-progressbar-label {
  position: absolute;
  top: -1.3em;
}

.p-progressbar-indeterminate .p-progressbar-value {
  border: 0 none;
}

.p-progressbar-indeterminate .p-progressbar-value:before {
  content: "";
  position: absolute;
  background-color: inherit;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
  -webkit-animation: p-progressbar-indeterminate-anim 2.1s
    cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
  animation: p-progressbar-indeterminate-anim 2.1s
    cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
}

.p-progressbar-indeterminate .p-progressbar-value:after {
  content: "";
  position: absolute;
  background-color: inherit;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
  -webkit-animation: p-progressbar-indeterminate-anim-short 2.1s
    cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
  animation: p-progressbar-indeterminate-anim-short 2.1s
    cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
  -webkit-animation-delay: 1.15s;
  animation-delay: 1.15s;
}

@-webkit-keyframes p-progressbar-indeterminate-anim {
  0% {
    left: -35%;
    right: 100%;
  }

  60% {
    left: 100%;
    right: -90%;
  }

  100% {
    left: 100%;
    right: -90%;
  }
}

@keyframes p-progressbar-indeterminate-anim {
  0% {
    left: -35%;
    right: 100%;
  }

  60% {
    left: 100%;
    right: -90%;
  }

  100% {
    left: 100%;
    right: -90%;
  }
}

@-webkit-keyframes p-progressbar-indeterminate-anim-short {
  0% {
    left: -200%;
    right: 100%;
  }

  60% {
    left: 107%;
    right: -8%;
  }

  100% {
    left: 107%;
    right: -8%;
  }
}

@keyframes p-progressbar-indeterminate-anim-short {
  0% {
    left: -200%;
    right: 100%;
  }

  60% {
    left: 107%;
    right: -8%;
  }

  100% {
    left: 107%;
    right: -8%;
  }
}

.p-progress-spinner {
  position: relative;
  margin: 0 auto;
  width: 100px;
  height: 100px;
  display: inline-block;
}

.p-progress-spinner::before {
  content: "";
  display: block;
  padding-top: 100%;
}

.p-progress-spinner-svg {
  animation: p-progress-spinner-rotate 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.p-progress-spinner-circle {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: p-progress-spinner-dash 1.5s ease-in-out infinite,
    p-progress-spinner-color 6s ease-in-out infinite;
  stroke-linecap: round;
}

@keyframes p-progress-spinner-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes p-progress-spinner-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes p-progress-spinner-color {
  100%,
  0% {
    stroke: #d62d20;
  }

  40% {
    stroke: #0057e7;
  }

  66% {
    stroke: #008744;
  }

  80%,
  90% {
    stroke: #ffa700;
  }
}

.p-radiobutton {
  display: inline-block;
  cursor: pointer;
  vertical-align: middle;
  margin-right: 0.25em;
}

.p-radiobutton .p-radiobutton-box {
  width: 1.125em;
  height: 1.125em;
  line-height: 1.125em;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  text-align: center;
}

.p-radiobutton .p-radiobutton-icon {
  display: block;
  font-size: 0.5em;
  line-height: inherit;
  margin-top: -1px;
}

.p-radiobutton + label {
  vertical-align: middle;
}

.p-rating a {
  margin-left: 0.25em;
  cursor: pointer;
}

.p-rating.p-state-disabled {
  cursor: default;
}

.p-fluid .fc .p-button {
  width: auto;
}

.p-scrollpanel-wrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  float: left;
}

.p-scrollpanel-content {
  height: 100%;
  width: calc(100% + 18px);
  padding: 0 0 0 0;
  position: relative;
  overflow: auto;
  box-sizing: border-box;
}

.p-scrollpanel-bar {
  position: relative;
  background: #c1c1c1;
  width: 9px;
  border-radius: 3px;
  top: 0;
  z-index: 2;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.25s linear;
}

.p-scrollpanel-hidden {
  display: none;
}

.p-scrollpanel:hover .p-scrollpanel-bar,
.p-scrollpanel:active .p-scrollpanel-bar {
  opacity: 1;
}

.p-scrollpanel-grabbed {
  -o-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.p-selectbutton {
  display: inline-block;
}

.p-selectbutton.p-state-error {
  padding: 0;
}

.p-selectbutton .p-button.p-state-focus {
  outline: none;
}

.p-sidebar {
  position: fixed;
  padding: 0.5em 1em;
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s;
}

.p-sidebar-left {
  top: 0;
  left: 0;
  width: 20em;
  height: 100%;
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
}

.p-sidebar-right {
  top: 0;
  right: 0;
  width: 20em;
  height: 100%;
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
}

.p-sidebar-top {
  top: 0;
  left: 0;
  width: 100%;
  height: 10em;
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
}

.p-sidebar-bottom {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10em;
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
}

.p-sidebar-full {
  width: 100%;
  height: 100%;
  left: 0;
  -webkit-transition: transform 0s;
  transition: transform 0s;
}

.p-sidebar-full.p-sidebar-active {
  animation: p-fadein 0.25s;
  -moz-animation: p-fadein 0.25s; /* Firefox */
  -webkit-animation: p-fadein 0.25s; /* Safari and Chrome */
  -o-animation: p-fadein 0.25s; /* Opera */
}

.p-sidebar-left.p-sidebar-active,
.p-sidebar-right.p-sidebar-active {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.p-sidebar-left.p-sidebar-sm,
.p-sidebar-right.p-sidebar-sm {
  width: 20em;
}

.p-sidebar-left.p-sidebar-md,
.p-sidebar-right.p-sidebar-md {
  width: 40em;
}

.p-sidebar-left.p-sidebar-lg,
.p-sidebar-right.p-sidebar-lg {
  width: 60em;
}

.p-sidebar-top.p-sidebar-active,
.p-sidebar-bottom.p-sidebar-active {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.p-sidebar-top.p-sidebar-sm,
.p-sidebar-bottom.p-sidebar-sm {
  height: 10em;
}

.p-sidebar-top.p-sidebar-md,
.p-sidebar-bottom.p-sidebar-md {
  height: 20em;
}

.p-sidebar-top.p-sidebar-lg,
.p-sidebar-bottom.p-sidebar-lg {
  height: 30em;
}

.p-sidebar-mask {
  position: fixed;
  width: 100%;
  height: 100%;
}

.p-sidebar-close {
  float: right;
}

@media screen and (max-width: 64em) {
  .p-sidebar-left.p-sidebar-lg,
  .p-sidebar-left.p-sidebar-md,
  .p-sidebar-right.p-sidebar-lg,
  .p-sidebar-right.p-sidebar-md {
    width: 20em;
  }
}

.p-slider {
  position: relative;
  text-align: left;
}

.p-slider .p-slider-handle {
  position: absolute;
  width: 1.2em;
  height: 1.2em;
  cursor: default;
  -ms-touch-action: none;
  touch-action: none;
  z-index: 2;
}

.p-slider .p-slider-handle.p-slider-handle-active {
  z-index: 2;
}

.p-slider .p-slider-range {
  position: absolute;
  font-size: 0.7em;
  display: block;
  border: 0;
  background-position: 0 0;
}

.p-slider-horizontal {
  height: 0.8em;
}

.p-slider-horizontal .p-slider-handle {
  top: -0.3em;
  margin-left: -0.6em;
}

.p-slider-horizontal .p-slider-range {
  top: 0;
  height: 100%;
}

.p-slider-horizontal .p-slider-range-min {
  left: 0;
}

.p-slider-horizontal .p-slider-range-max {
  right: 0;
}

.p-slider-vertical {
  width: 0.8em;
  height: 100px;
}

.p-slider-vertical .p-slider-handle {
  left: -0.3em;
  margin-left: 0;
  margin-bottom: -0.6em;
}

.p-slider-vertical .p-slider-range {
  left: 0;
  width: 100%;
}

.p-slider-vertical .p-slider-range-min {
  bottom: 0;
}

.p-slider-vertical .p-slider-range-max {
  top: 0;
}

.p-slider-animate .p-slider-handle {
  transition: left 0.3s;
}

.p-spinner {
  display: inline-block;
  overflow: visible;
  padding: 0;
  position: relative;
  vertical-align: middle;
}

.p-spinner-input {
  vertical-align: middle;
  padding-right: 1.5em;
}

.p-spinner-button {
  cursor: default;
  display: block;
  height: 50%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  right: 0;
  text-align: center;
  vertical-align: middle;
  width: 1.5em;
}

.p-spinner .fa {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -0.5em;
  margin-left: -0.5em;
  width: 1em;
}

.p-spinner-up {
  top: 0;
}

.p-spinner-down {
  bottom: 0;
}
/* Fluid */
.p-fluid .p-spinner {
  width: 100%;
}

.p-fluid .p-spinner .p-spinner-input {
  padding-right: 2em;
  width: 100%;
}

.p-fluid .p-spinner .p-spinner-button {
  width: 1.5em;
}

.p-fluid .p-spinner .p-spinner-button .fa {
  left: 0.375em;
}

.p-splitbutton {
  position: relative;
  display: inline-block;
  zoom: 1;
  margin-right: 0.5em;
}

.p-splitbutton .p-button.p-splitbutton-menubutton {
  width: 2em;
}

.p-splitbutton.p-state-disabled button {
  cursor: default;
}

.p-fluid .p-splitbutton {
  width: 100%;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.p-fluid .p-splitbutton .p-button:first-child {
  width: calc(100% - 2em);
}

.p-fluid .p-splitbutton .p-button.p-splitbutton-menubutton {
  width: 2em;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.p-splitbutton.p-button-secondary .p-button.p-state-default:first-child {
  border-right: 0 none;
}

.p-steps ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.p-steps .p-steps-item {
  float: left;
  box-sizing: border-box;
  cursor: pointer;
}

.p-steps.p-steps-readonly .p-steps-item {
  cursor: auto;
}

.p-steps .p-steps-item .p-menuitem-link {
  text-decoration: none;
  display: block;
  padding: 1em;
  position: relative;
  text-align: center;
}

.p-steps .p-steps-item.p-state-highlight .p-menuitem-link,
.p-steps .p-steps-item.p-state-disabled .p-menuitem-link {
  cursor: default;
}

.p-steps .p-steps-number {
  font-size: 200%;
  display: block;
}

.p-steps .p-steps-title {
  display: block;
  white-space: nowrap;
}
/* Responsive */
@media (max-width: 40em) {
  .p-steps .p-steps-item .p-menuitem-link {
    padding: 0.5em;
  }

  .p-steps .p-steps-item .p-steps-title {
    display: none;
  }
}

.p-steps .p-steps-item {
  width: 25%;
}
/** TabMenu **/
.p-tabmenu {
}

.p-tabmenu .p-tabmenu-nav {
  margin: 0;
  padding: 0.25em 0.5em 0 0.25em;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
  list-style: none;
  float: left;
  position: relative;
  margin: 0 0.2em 1px 0;
  padding: 0;
  white-space: nowrap;
  display: block;
  border-bottom: 0;
  top: 1px;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem a {
  float: left;
  padding: 0.5em 1em;
  text-decoration: none;
}

.p-tabmenu .p-tabmenu-nav a {
  padding: 0.5em 1em;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-icon {
  float: left;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-state-disabled a {
  cursor: default;
}

.p-tabview {
  padding: 0.25em;
}

.p-tabview .p-tabview-nav {
  margin: 0;
}

.p-tabview .p-tabview-nav li {
  list-style: none;
  float: left;
  position: relative;
  margin: 0 0.125em 1px 0;
  padding: 0;
  white-space: nowrap;
}

.p-tabview .p-tabview-nav li a {
  float: left;
  padding: 0.5em 1em;
  text-decoration: none;
}

.p-tabview .p-tabview-nav li.p-tabview-selected a,
.p-tabview .p-tabview-nav li.p-state-disabled a,
.p-tabview .p-tabview-nav li.p-state-processing a {
  cursor: text;
}

.p-tabview .p-tabview-nav li a,
.p-tabview.p-tabview-collapsible .p-tabview-nav li.p-tabview-selected a {
  cursor: pointer;
}

.p-tabview .p-tabview-panel {
  border-width: 0;
  padding: 1em;
  background: none;
}

.p-tabview .p-tabview-nav li {
  display: block;
}

.p-tabview .p-tabview-nav li .p-tabview-left-icon,
.p-tabview .p-tabview-nav li .p-tabview-right-icon,
.p-tabview .p-tabview-nav li .p-tabview-title {
  vertical-align: middle;
  margin-right: 0.25em;
}

.p-tabview .p-tabview-nav li .p-tabview-close {
  margin: 0.5em 0.3em 0 0;
  cursor: pointer;
}
/* per orientation settings */
/* top and bottom */
.p-tabview.p-tabview-top > .p-tabview-nav li {
  border-bottom: 0;
  top: 1px;
}

.p-tabview.p-tabview-top > .p-tabview-nav {
  padding: 0.2em 0.2em 0;
}

.p-tabview.p-tabview-bottom > .p-tabview-nav {
  padding: 0 0.2em 0.2em;
}

.p-tabview.p-tabview-bottom > .p-tabview-nav li {
  border-top: 0;
}
/* left and right*/
.p-tabview-left:after,
.p-tabview-right:after {
  clear: both;
  content: ".";
  display: block;
  height: 0;
  visibility: hidden;
}

.p-tabview-left > .p-tabview-nav {
  float: left;
  width: 25%;
  height: 300px;
  background-image: none;
  padding-top: 1px;
}

.p-tabview-left > .p-tabview-panels {
  float: right;
  width: 75%;
}

.p-tabview.p-tabview-left > .p-tabview-nav li,
.p-tabview.p-tabview-right > .p-tabview-nav li {
  display: block;
  float: right;
  white-space: normal;
  width: 99%;
}

.p-tabview.p-tabview-left > .p-tabview-nav li {
  margin: 0 0 1px 0;
  border-right: 0 none;
}

.p-tabview.p-tabview-right > .p-tabview-nav {
  float: right;
  width: 25%;
  height: 300px;
  background-image: none;
  padding-top: 1px;
}

.p-tabview.p-tabview-right > .p-tabview-panels {
  float: left;
  width: 75%;
}

.p-tabview.p-tabview-right > .p-tabview-nav li {
  margin: 0 0 1px 0;
  border-left: 0 none;
}
/* RTL */
.p-rtl .p-tabview .p-tabview-nav li {
  float: right;
}

.p-toolbar {
  padding: 0.25em 0.5em;
}

.p-toolbar-group-left {
  float: left;
}

.p-toolbar-group-right {
  float: right;
}

.p-tooltip {
  position: absolute;
  display: none;
  padding: 0.25em 0.5em;
  max-width: 12.5em;
}

.p-tooltip.p-tooltip-right,
.p-tooltip.p-tooltip-left {
  padding: 0 0.25em;
}

.p-tooltip.p-tooltip-top,
.p-tooltip.p-tooltip-bottom {
  padding: 0.25em 0;
}

.p-tooltip .p-tooltip-text {
  padding: 0.125em 0.5em;
  background-color: rgb(76, 76, 76);
  color: #ffffff;
  white-space: pre-line;
}

.p-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.p-tooltip-right .p-tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -0.25em;
  border-width: 0.25em 0.25em 0.25em 0;
  border-right-color: rgb(76, 76, 76);
}

.p-tooltip-left .p-tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -0.25em;
  border-width: 0.25em 0 0.25em 0.25em;
  border-left-color: rgb(76, 76, 76);
}

.p-tooltip.p-tooltip-top {
  padding: 0.25em 0;
}

.p-tooltip-top .p-tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -0.25em;
  border-width: 0.25em 0.25em 0;
  border-top-color: rgb(76, 76, 76);
}

.p-tooltip-bottom .p-tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -0.25em;
  border-width: 0 0.25em 0.25em;
  border-bottom-color: rgb(76, 76, 76);
}

.p-tree {
  width: 18em;
}

.p-tree .p-treenode-selectable.p-treenode-content {
  cursor: pointer;
}

.p-tree .p-tree-container {
  height: 100%;
  margin: 0;
  overflow: auto;
  padding: 0.25em;
  white-space: nowrap;
}

.p-tree .p-treenode-children {
  margin: 0;
  padding: 0 0 0 1em;
}

.p-tree .p-treenode {
  background-attachment: scroll;
  background-color: transparent;
  background-image: none;
  background-position: 0 0;
  background-repeat: repeat-y;
  list-style: none outside none;
  margin: 0;
  padding: 0.125em 0 0 0;
}

.p-tree .p-treenode-droppoint {
  height: 4px;
  list-style-type: none;
}

.p-tree .p-treenode-droppoint-active {
  border: 0 none;
}

.p-tree .p-tree-toggler {
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
}

.p-tree .p-treenode-icon {
  display: inline-block;
  vertical-align: middle;
}

.p-tree .p-treenode-label {
  display: inline-block;
  padding: 0 0.25em;
  vertical-align: middle;
}

.p-tree .p-treenode-label.p-state-hover,
.p-tree .p-treenode-label.p-state-highlight {
  font-weight: normal;
  border: 0 none;
}

.p-tree .p-treenode.p-treenode-leaf > .p-treenode-content > .p-tree-toggler {
  visibility: hidden;
}

.p-tree .p-chkbox-box {
  cursor: pointer;
}

.p-tree .p-chkbox {
  display: inline-block;
  vertical-align: middle;
}

.p-tree .p-chkbox .p-chkbox-icon {
  margin-left: 1px;
}
/** Fluid **/
.p-fluid .p-tree {
  width: 100%;
}
/** Horizontal Tree **/
.p-tree-horizontal {
  width: auto;
  padding: 0.5em 0;
  overflow: auto;
}

.p-tree.p-tree-horizontal table,
.p-tree.p-tree-horizontal tr,
.p-tree.p-tree-horizontal td {
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}

.p-tree.p-tree-horizontal .p-tree-toggler {
  vertical-align: middle;
  margin: 0;
}

.p-tree-horizontal .p-treenode-content {
  font-weight: normal;
  padding: 0.4em 1em 0.4em 0.2em;
}

.p-tree.p-tree-horizontal .p-tree-node-label {
  margin: 0;
}

.p-tree-horizontal .p-treenode-parent .p-treenode-content {
  font-weight: normal;
  white-space: nowrap;
}

.p-tree.p-tree-horizontal .p-treenode {
  /* background: url("./images/line.gif") repeat-x scroll center center transparent; */
  padding: 0.25em 2.5em;
}

.p-tree.p-tree-horizontal .p-treenode.p-treenode-leaf,
.p-tree.p-tree-horizontal .p-treenode.p-treenode-collapsed {
  padding-right: 0;
}

.p-tree.p-tree-horizontal .p-treenode-children {
  padding: 0;
  margin: 0;
}

.p-tree.p-tree-horizontal .p-treenode-connector {
  width: 1px;
}

.p-tree.p-tree-horizontal .p-treenode-connector-table {
  height: 100%;
  width: 1px;
}

.p-tree.p-tree-horizontal .p-treenode-connector-line {
  /* background: url("./images/line.gif") repeat-y scroll 0 0 transparent; */
  width: 1px;
}

.p-tree.p-tree-horizontal table {
  height: 0;
}

.p-tree.p-tree-horizontal .p-chkbox {
  vertical-align: bottom;
  margin-right: 0.25em;
}

.p-treetable {
  position: relative;
}

.p-treetable table {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
}

.p-treetable .p-treetable-header,
.p-treetable .p-treetable-footer {
  text-align: center;
  padding: 0.5em 0.75em;
}

.p-treetable .p-treetable-header {
  border-bottom: 0 none;
}

.p-treetable .p-treetable-footer {
  border-top: 0 none;
}

.p-treetable th,
.p-treetable tfoot td {
  text-align: center;
}

.p-treetable thead th,
.p-treetable tbody td,
.p-treetable tfoot td {
  padding: 0.25em 0.5em;
  overflow: hidden;
  white-space: nowrap;
  border-width: 1px;
  border-style: solid;
}

.p-treetable tbody td {
  border-color: inherit;
}

.p-treetable tbody td:first-child span {
  vertical-align: middle;
}

.p-treetable .p-treetable-toggler {
  vertical-align: middle;
  cursor: pointer;
  text-decoration: none;
}

.p-treetable .p-treetable-checkbox {
  margin-right: 0.5em;
}

.p-treetable .p-treetable-checkbox .p-chkbox-icon {
  margin-left: 1px;
}

.p-treetable .p-treetable-row.p-treetable-row-selectable {
  cursor: pointer;
}

.p-treetable .p-treetable-row.p-state-highlight {
  border: 0 none;
}

.p-treetable tr.p-state-hover {
  border-color: inherit;
  font-weight: inherit;
}

.p-treetable .p-treetable-indent {
  width: 1em;
  height: 1em;
  float: left;
}

.p-treetable .p-sortable-column {
  cursor: pointer;
}

.p-treetable .p-sortable-column-icon {
  display: inline-block;
  margin-left: 0.125em;
}

.p-treetable tr.p-state-highlight {
  cursor: pointer;
}
/* PrimeReact */
.p-treetable td.p-treetable-child-table-container {
  padding: 0;
  border: 0 none;
}

.p-treetable .p-treetable-row {
  display: table-row;
  border-bottom: 0 transparent;
}

.p-treetable tbody .p-treetable-row td {
  border: 0 none;
}

.p-treetable tbody .p-treetable-row td input {
  outline: 0 none;
}
