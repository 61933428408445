.p-datatable {
    position: relative;
}

.p-datatable table {
	border-collapse:collapse;
    width: 100%;
    table-layout: fixed;
    min-width:768px;
}

.p-datatable .p-datatable-header,
.p-datatable .p-datatable-caption,
.p-datatable .p-datatable-footer {
    text-align: center;
	padding: .5em .75em;
    box-sizing: border-box;
}

.p-datatable .p-datatable-caption,
.p-datatable .p-datatable-header {
    border-bottom: 0 none;
}

.p-datatable .p-datatable-footer {
    border-top: 0 none;
}

.p-datatable thead th, .p-datatable tfoot td {
    text-align: center;
}

.p-datatable thead tr {
    border-width: 0;
}

.p-datatable .p-datatable-thead > tr > th,
.p-datatable .p-datatable-tfoot > tr > td,
.p-datatable .p-datatable-data > tr > td {
    border-color: inherit;
    box-sizing: border-box;
    padding: .25em .5em;
    border-width: 1px;
    border-style: solid;
    background: #fff;
}

.p-datatable-auto-layout > .p-datatable-wrapper > table {
    table-layout: auto;
}

.p-datatable.p-datatable-resizable .p-datatable-thead > tr > th,
.p-datatable.p-datatable-resizable .p-datatable-tfoot > tr > td,
.p-datatable.p-datatable-resizable .p-datatable-data > tr > td {
    overflow-x: hidden;
}

.p-datatable .p-datatable-thead > tr > th,
.p-datatable .p-datatable-tfoot > tr > td {
    font-weight: normal;
}

.p-datatable tbody {
    outline: 0;
}

.p-datatable .p-sortable-column {
    cursor: pointer;
}

.p-datatable .p-sortable-column-icon {
    display: inline-block;
    margin-left: .125em;
}

.p-datatable tr.p-state-highlight {
    cursor: pointer;
}

/* Scrollable */
.p-datatable-scrollable-body {
    overflow:auto;
}

.p-datatable-scrollable .p-datatable-scrollable-header,
.p-datatable-scrollable .p-datatable-scrollable-footer {
    position: relative;
    border: 0 none;
    overflow: hidden;
}

.p-datatable-scrollable .p-datatable-scrollable-header td {
    font-weight: normal;
}

.p-datatable .p-datatable-scrollable-body  {
    min-height: 0%;
}

.p-datatable .p-datatable-data tr.p-state-hover,
.p-datatable .p-datatable-data tr.p-state-highlight {
    border-color: inherit;
    font-weight: inherit;
    cursor: pointer;
}

.p-datatable .p-datatable-data tr.p-rowgroup-header td a,
.p-datatable .p-datatable-data tr.p-rowgroup-header td span.p-rowgroup-header-name {
    display: inline-block;
    vertical-align: middle;
}

.p-datatable-scrollable-theadclone {
    height: 0;
}

.p-datatable-scrollable-theadclone tr {
    height: 0;
}

.p-datatable-scrollable-theadclone th.p-state-default {
    height: 0;
    border-bottom-width: 0;
    border-top-width: 0;
    padding-top: 0;
    padding-bottom: 0;
    outline: 0 none;
}

.p-datatable-scrollable-theadclone th span.p-column-title {
  display: block;
  height: 0;
}

.p-datatable .p-paginator {
    padding: .125em;
}

.p-datatable .p-paginator-top {
    border-bottom-width: 0;
}

.p-datatable .p-paginator-bottom {
    border-top-width: 0;
}

.p-datatable-rtl {
    direction: rtl;
}

.p-datatable-rtl.p-datatable thead th,
.p-datatable-rtl.p-datatable tfoot td {
    text-align: right;
}

/* Row Toggler */
.p-row-toggler {
    cursor: pointer;
}

/* Resizable */
.p-datatable .p-column-resizer {
    display: block;
    position: absolute !important;
    top: 0;
    right: 0;
    margin: 0;
    width: .5em;
    height: 100%;
    padding: 0px;
    cursor:col-resize;
    border: 1px solid transparent;
}

.p-datatable.p-datatable-resizable .p-datatable-thead > tr > th:last-child .p-column-resizer {
    display: none;
}

.p-datatable .p-column-resizer-helper {
    width: 1px;
    position: absolute;
    z-index: 10;
    display: none;
}

.p-datatable-resizable {
    padding-bottom: 1px;     /*fix for webkit overlow*/
}

.p-datatable-resizable>.p-datatable-tablewrapper {
    overflow-x: auto;
}

.p-datatable-resizable thead th,
.p-datatable-resizable tbody td,
.p-datatable-resizable tfoot td {
    white-space: nowrap;
}

.p-datatable-resizable th.p-resizable-column {
    background-clip: padding-box;
    position: relative;
}

/** Reflow **/
.p-datatable-reflow .p-datatable-data td .p-column-title {
    display: none;
}

/* Filter */
.p-datatable  {
    display: block;
    width: 100%;
    box-sizing: border-box;
    margin-top: .25em;
  
}

.p-column-filter {
    
    width: 100%;
    box-sizing: border-box;
    margin-top: .25em;
    display: none;
}

/* Editing */
.p-datatable .p-editable-column input {
    width: 100%;
    outline: 0;
}

.p-datatable .p-datatable-data > tr > td.p-editable-column {
    padding: .5em;
}

.p-datatable .p-datatable-data > tr > td.p-editable-column.p-cell-editing {
    padding: 1px;
}

.p-datatable-stacked thead th,
.p-datatable-stacked tfoot td {
    display: none !important;
}

.p-datatable.p-datatable-stacked .p-datatable-data > tr > td {
    text-align: left;
    display: block;
    border: 0 none;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    float: left;
    clear: left;
}

.p-datatable.p-datatable-stacked .p-datatable-data.p-widget-content {
    border: 0 none;
}

.p-datatable-stacked .p-datatable-data tr.p-widget-content {
    border-left: 0 none;
    border-right: 0 none;
}

.p-datatable-stacked .p-datatable-data td .p-column-title {
    padding: .4em;
    min-width: 30%;
    display: inline-block;
    margin: -.4em 1em -.4em -.4em;
    font-weight: bold;
}

.p-datatable .p-selection-column .p-chkbox,
.p-datatable .p-selection-column .p-radiobutton {
     margin: 0;
     display: block;
}

.p-datatable .p-selection-column .p-chkbox-box,
.p-datatable .p-selection-column .p-radiobutton-box {
    display: block;
    box-sizing: border-box;
    margin: 0;
}

.p-datatable-scrollable-wrapper {
    position: relative;
}

.p-datatable-scrollable-view {
    
}

.p-datatable-frozen-view .p-datatable-scrollable-body {
    overflow: hidden;
}

.p-datatable-frozen-view + .p-datatable-scrollable-view {
    position: absolute;
    top: 0px;
}

.p-datatable .p-datatable-load-status {
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
}

.p-datatable.p-datatable-virtual-scrollable .p-datatable-scrollable-table-wrapper {
    position: relative;
}

.p-datatable.p-datatable-virtual-scrollable .p-datatable-scrollable-table-wrapper > table {
    position: absolute;
    top: 0px;
    left: 0px;
}

.p-datatable-loader {
    position: absolute;
    width: 100%;
    height: 100%;
}

.p-datatable-loader-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=10)";
    opacity: 0.1;
    z-index: 1;
}

.p-datatable-loader-content {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 2;
    margin-top: -1em;
    margin-left: -1em;
}

/* Reorder */
.p-datatable-reorder-indicator-up,
.p-datatable-reorder-indicator-down {
    position: absolute;
    display: none;
}

@media ( max-width: 35em ) {
    .p-datatable-reflow thead th,
    .p-datatable-reflow tfoot td {
        display: none !important;
    }

    .p-datatable-reflow .p-datatable-data > tr > td {
        text-align: left;
        display: block;
        border: 0 none;
        width: 100% !important;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		float: left;
		clear: left;
    }

    .p-datatable-reflow .p-datatable-data.p-widget-content {
        border: 0 none;
    }

    .p-datatable-reflow .p-datatable-data tr.p-widget-content {
        border-left: 0 none;
        border-right: 0 none;
    }

    .p-datatable-reflow .p-datatable-data td .p-column-title {
        padding: .4em;
        min-width: 30%;
        display: inline-block;
        margin: -.4em 1em -.4em -.4em;
        font-weight: bold;
    }
    
    .p-datatable-reflow.p-datatable-scrollable .p-datatable-scrollable-body colgroup {
        display: block;
    }
}