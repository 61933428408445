@media (max-width: 819px) {
  .multi-grid-page--sticky {
    margin-top: -20px;
  }
}
@media (min-width: 820px) and (max-width: 1149px) {
  margin-top: -20px;
}

.multi-grid-page--sticky hr {
  margin-top: 1rem;
  width: 140%;
  margin-bottom: 0;
  border-top: 3px solid rgba(0, 0, 0, 0.1);
  margin-left: -100px;
}

.multi-grid-page--sticky .grid-header--sticky hr {
  min-width: 140%;
  margin-bottom: 0;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  margin-left: -100px;
}
.grid-label {
  color: black;
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0;
}
.contract-badge {
  background-color: rgba(20, 114, 125, 0.15);
  border-radius: 3px 3px 3px 3px;
  width: 80px;
  height: 22px;
  letter-spacing: 0;
  margin-bottom: 5px;
}
.contract-badge span {
  color: #14727d;
  font-size: 12px;
  padding-left: 11px;
  font-weight: 700;
  line-height: 22px;
  display: inline-block;
}
.tab-strip {
  margin-left: -25px;
}
@media (max-width: 819px) {
  .tab-strip {
    width: auto;
    width: calc(140%);
  }
}
@media (min-width: 820px) and (max-width: 1149px) {
  .tab-strip {
    min-height: 100vh;
  }
}
@media (min-width: 820px) {
  .tab-strip {
    position: relative;
    min-width: 240px;
    margin-right: 30px;
    box-shadow: 3px 0px 5px 0px rgba(0, 0, 0, 0.15);
  }
}
.tab-strip .active {
  background-color: #f1f1f1;
}
.tab-strip .tab-title {
  flex: 1;
  display: inline-block;
  padding: 0px;
  margin-right: 20px;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
}
.tab-strip .tab-title span {
  font-size: 12px;
  background-color: #dcdcdc;
  border-radius: 10px;
  display: inline-block;
  height: 20px;
  line-height: 10px;
  margin-left: 8px;
  min-width: 22px;
  padding: 5px;
  text-align: center;
}
.tab-strip .tab-title-after {
  content: "\f054";
  font-family: "FontAwesome";
  font-weight: normal;
  font-style: normal;
  font-size: 10px;
  float: right;
  right: 0px;
  position: absolute;
  color: #000000;
}

.tab-strip > div {
  display: flex;
  height: 50px;
  align-items: center;
  border-top: 2px solid #dcdcdc;
  margin-bottom: 0px;
  padding-left: 20px;
  width: 100%;
}
@media (max-width: 819px) {
  .tab-strip > div:first-child {
    border-top: none;
  }
}
@media (min-width: 820px) and (max-width: 1149px) {
  .tab-strip > div:first-child {
    border-top: none;
  }
}
.tab-strip > div:last-child {
  border-bottom: 2px solid #dcdcdc;
}
.tab-strip > div:active:hover {
  background-color: #f1f1f1;
}
.grid-sub-label {
  color: #000000;
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0;
}
.grid-bottom {
  min-height: 500px;
}
.grid-bottom:empty {
  display: none;
}
@media (max-width: 1149px) {
  .grid-bottom {
    height: 100%;
  }
}
@media (min-width: 820px) and (max-width: 1149px) {
  .grid-bottom {
    display: flex;
  }
}
.grid-header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media (min-width: 820px) and (max-width: 1149px) {
  .grid-header--title {
    display: inline-flex;
    margin-top: 20px;
  }
}
@media (max-width: 1149px) {
  .grid-header--sticky {
    position: sticky;
    top: 0px;
    background-color: #ffffff;
    z-index: 800;
    margin: 0 -20px;
    padding: 20px 20px 0px 20px;
    align-items: center;
  }
}

@media (min-width: 820px) and (max-width: 1149px) {
  .grid-header--sticky {
    padding-top: 0px;
  }
}

.grid-toolbar {
  position: relative;
  align-self: flex-end !important;
  float: right;
  display: flex;
  flex-wrap: nowrap;
  height: 40px;
  padding-top: 13px;
}
@media (max-width: 819px) {
  .grid-toolbar {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-items: center;
    align-items: center;
    top: 0px;
  }
}
@media (min-width: 820px) and (max-width: 1149px) {
  .grid-toolbar {
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-items: center;
    top: 0px;
    padding-top: 0px;
  }
}
.grid-toolbar .view-selector-wrapper {
  display: flex;
  flex-wrap: nowrap;
  font-size: 20px;
  padding-top: 9px;
}

.grid-toolbar .view-selector-wrapper .viewSeperator {
  color: #9b9b9b;
  font-size: 20px;
  margin-left: 15px;
  margin-right: 15px;
}

.grid-toolbar .view-selector-wrapper .viewText {
  font-size: 15px;
  color: #949494;
  margin-right: 10px;
  margin-top: 2px;
}

.grid-toolbar .view-selector-wrapper .icon-view-selector {
  opacity: 0.2;
  cursor: pointer;
  height: 14px;
  width: 14px;
}

.multi-grid-page
  .grid-toolbar
  .view-selector-wrapper
  .icon-view-selector.disable {
  cursor: not-allowed;
  opacity: 0.1;
  pointer-events: none;
}

.multi-grid-page
  .grid-toolbar
  .view-selector-wrapper
  .icon-view-selector.selected {
  opacity: 1;
}

.grid-toolbar .audience-toggle {
  color: #777777;
  font-size: 12px;
  font-weight: bold;
  font-family: "Source Sans Pro", sans-serif;
}

.grid-toolbar .hidden-search-wrapper {
  display: flex;
  flex-wrap: nowrap;
}

@media (max-width: 819px) {
  .grid-toolbar .hidden-search-wrapper--no-margin {
    margin-right: 0px;
  }
}

@media (max-width: 819px) {
  .grid-toolbar .hidden-search-wrapper {
    align-self: auto !important;
  }
}

@media (max-width: 1149px) {
  .grid-toolbar .hidden-search-wrapper {
    height: auto;
    width: auto;
    flex: 1;
  }
}

@media (min-width: 820px) and (max-width: 1149px) {
  .grid-toolbar .hidden-search-wrapper {
    margin-top: 20px;
    margin-right: 0px;
  }
}

.grid-toolbar .hidden-search-wrapper .hidden-search-box {
  border: none;
  box-shadow: none;
  font-size: 14px;
  height: 36px;
  cursor: pointer;
}

@media (max-width: 1149px) {
  .grid-toolbar .hidden-search-wrapper .hidden-search-box {
    border: 1px solid #ced4da;
    background-color: #f1f1f1 !important;
    outline: 0;
    cursor: text;
  }
}

.grid-toolbar .hidden-search-wrapper .search-textBox-wrapper {
  display: flex;
  width: 300px;
}

.grid-toolbar .hidden-search-wrapper .search-textBox-wrapper input {
  margin-right: 10px;
}

.grid-toolbar .hidden-search-wrapper .search-textBox-wrapper svg {
  position: relative;
  top: 10px;
  font-size: 20px;
}

.grid-toolbar .hidden-search-wrapper .is-visible {
  border: 1px solid #ced4da;
  background-color: #f1f1f1 !important;
  outline: 0;
  cursor: text;
  margin-right: 30px;
  display: inline-block;
}

.grid-toolbar .icon {
  color: #4a4a4a;
  font-size: 18px;
  font-family: "Glyphicons Halflings";
  font-style: normal;
  font-weight: 400;
  cursor: pointer;
}

.grid-toolbar .hidden-search-wrapper .searchIcon {
  margin-right: 15px;
}

@media (max-width: 1149px) {
  .grid-toolbar .hidden-search-wrapper .searchIcon {
    margin-right: 0px;
  }
}

@media (max-width: 819px) {
  .grid-toolbar .hidden-search-wrapper .search-textBox-wrapper {
    width: 100%;
  }
}

.grid-toolbar .hidden-search-wrapper-mobile {
  margin-top: 0;
  margin-bottom: 10px;
}

.grid-toolbar .hidden-search-wrapper.mobile-search {
  margin-bottom: 10px;
  margin-top: 5px;
  width: 100%;
}

.grid-toolbar .hidden-search-wrapper .inlineIcon {
  right: 0px;
  position: absolute;
  top: 1px;
}

.grid-toolbar .context-menu-wrapper {
  color: #4a4a4a;
  width: 20px;
  padding-top: 10px;
}

.grid-toolbar .context-menu-wrapper .context-menu {
  -moz-box-shadow: -1px -1px 5px 3px #c7bfc7;
  -webkit-box-shadow: -1px -1px 5px 3px #c7bfc7;
  background-color: #fff;
  box-shadow: -1px -1px 5px 3px #c7bfc7;
  display: none;
  position: absolute;
  top: 30px;
  width: 150px;
  z-index: 1;
  right: -10px;
}

.multi-grid-page
  .grid-toolbar
  .context-menu-wrapper
  .context-menu
  .context-item {
  line-height: 40px;
  cursor: pointer;
  font-size: 14px;
}

.multi-grid-page
  .grid-toolbar
  .context-menu-wrapper
  .context-menu
  .context-item:hover {
  background-color: #f1f1f1;
}

.multi-grid-page
  .grid-toolbar
  .context-menu-wrapper
  .context-menu
  .context-item
  span {
  margin-left: 10px;
}

.grid-toolbar .context-menu-wrapper:hover .context-menu {
  display: block;
}

.grid-tab-wrapper {
  background: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  margin-top: 20px !important;
  margin-bottom: 18px !important;
}

.grid-tab-wrapper.empty {
  border-bottom: none;
}

.grid-tab-wrapper label {
  display: inline-block;
  margin: 0 0 -1px;
  padding: 15px 25px;
  font-weight: 600;
  text-align: center;
  color: black;
  border: 1px solid transparent;
  font-size: 16px;
}

.grid-tab-wrapper label.checked {
  color: black;
  border: 1px solid #949494;
  border-top: 3px solid #005da6 !important;
  border-bottom: 2px solid #fff;
}

.grid-tab-wrapper label:hover {
  cursor: pointer;
}

.grid-tab-wrapper label span {
  background-color: #dcdcdc;
  border-radius: 10px;
  display: inline-block;
  height: 20px;
  line-height: 10px;
  margin-left: 5px;
  min-width: 22px;
  padding: 5px;
}
.grid-container {
  overflow-x: auto;
  padding-bottom: 0px;
  padding-top: 10px;
  padding-left: 8px;
}

.custom-primereact {
  padding-bottom: 50px;
  position: relative;
  height: 100%;
  width: 100%;
}

@media (max-width: 1149px) {
  .custom-primereact {
    width: 100%;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .grid-toolbar .hidden-search-wrapper .searchIcon {
      margin-right: 0;
    }
  }

  @-moz-document url-prefix() {
    .grid-toolbar .hidden-search-wrapper .searchIcon {
      margin-right: 0;
    }
  }
}

.custom-primereact.full-row {
  box-shadow: 0 -2px 2px 0 #dcdcdc;
  padding-top: 20px;
}

.custom-primereact .selection-bar {
  display: block;
  background-color: #d0edfc;
  font-weight: 700;
  font-size: 16px;
  padding: 15px;
  margin-bottom: 15px;
}

.custom-primereact .selection-bar .buttons {
  float: right;
  color: #777777;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 0 !important;
}

.custom-primereact .selection-bar .buttons .confirm {
  cursor: pointer;
  margin-right: 30px;
}

.custom-primereact .selection-bar .buttons .deny {
  cursor: pointer;
  margin-right: 10px;
}

.custom-primereact .selection-bar .buttons .fa-remove {
  color: black;
  cursor: pointer;
}

.custom-primereact .selection-bar .buttons .disabled {
  opacity: 0.4;
  pointer-events: none;
  cursor: text;
}
.custom-primereact .p-datatable-header {
  display: none;
}
.custom-primereact .p-column-title {
  font-family: "Roboto", "Trebuchet MS", "Arial", "Helvetica", "sans-serif";
  font-size: 16px !important;
  font-weight: 600 !important;
}
.custom-primereact .p-paginator {
  padding-top: 30px;
}
.custom-primereact .p-paginator-page {
  width: 36px;
  font-size: 14px;
  color: #777777;
  font-weight: 900;
}
.custom-primereact .p-dropdown {
  margin-top: 5px;
}
.custom-primereact .p-dropdown-trigger {
  border-color: #dcdcdc;
}
.custom-primereact .p-dropdown-label {
  font-size: 14px;
  font-weight: 600;
  width: 70px;
}
.custom-primereact .p-selection-column {
  pointer-events: none;
}
.custom-primereact .p-selection-column .p-chkbox {
  pointer-events: all;
}
.custom-primereact .showGridPageLabel {
  font-size: 16px;
  margin-top: -25px;
  z-index: 1;
}
.custom-primereact .p-dropdown-panel {
  font-size: 16px;
}

.card-view-wrapper .card-view .order-label {
  color: #000000;
  font-weight: 600;
  font-size: 14px;
  margin-right: 5px;
}
.card-view-wrapper .card-view .view-details {
  color: #777777;
  text-decoration: underline;
  cursor: pointer;
  font-size: 14px;
}
.card-view-wrapper .card-view .created-label {
  color: #000000;
  float: right;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

.card-view-wrapper .card-view .created-label-date {
  color: #000000;
  float: right;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: -5px;
}

.card-view-wrapper .card-view .part-info .formulary-approval {
  color: #ff9505;
  font-weight: 600;
  float: right;
}
.card-view-wrapper .card-view .part-info .formulary-approval .fa-exclamation {
  margin-right: 5px;
  color: #ffca82;
  border-width: 1px;
  border: 1px solid #ff9505;
  border-radius: 50%;
  padding: 10px;
  width: 20px;
  padding-left: 6px;
  height: 20px;
  padding-top: 2px;
}
.card-view-wrapper .card-view .part-info .price {
  font-size: 16px;
  font-weight: 600;
}
.card-view-wrapper .card-view .part-info .detail {
  font-size: 16px;
  font-weight: normal;
  display: block;
}
.card-view-wrapper .card-view .part-info .detail span {
  font-weight: 600;
}
.card-view-wrapper .card-view .card-ship-info {
  background-color: #f1f1f1;
  overflow: hidden;
  padding: 10px 29px 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 95px;
}
.card-view-wrapper .card-view .card-ship-info .history-link {
  float: right;
  margin-top: 5px;
  font-size: 13px;
  color: #949494;
  cursor: pointer;
  display: none;
}
.multi-grid-page
  .card-view-wrapper
  .card-view
  .card-ship-info:hover
  .history-link {
  display: block;
}
.card-view-wrapper .card-view .card-rga-info {
  overflow: hidden;
  padding: 10px 29px 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 90px;
  height: 70px;
  padding: 25px;
  font-size: 22px;
  background-color: #f1f1f1;
}
.card-view-wrapper .card-view .card-rga-info.overDue {
  background-color: #facbcb;
}
.card-view-wrapper .card-view .card-rga-info.isDue {
  background-color: #ffca82;
}
.card-view-wrapper .card-view .card-missing-paperwork {
  overflow: hidden;
  margin: 16px -30px 0 -30px;
  padding: 27px 30px 23px 30px;
  position: relative;
  height: auto;
  font-size: 16px;
  background-color: #f1f1f1;
  line-height: 22px;
}
.card-view-wrapper .card-view .card-missing-paperwork--bottom {
  padding: 10px 29px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 80px;
  margin: 0;
  height: auto;
}
.card-view-wrapper
  .card-view
  .card-missing-paperwork
  > .card-missing-paperwork-title {
  font-weight: 600;
  margin-bottom: 5px;
}
.multi-grid-page
  .card-view-wrapper
  .card-view
  .card-missing-paperwork
  > .card-rga-printed {
  margin-bottom: 5px;
}
.multi-grid-page
  .card-view-wrapper
  .card-view
  .card-missing-paperwork
  > .card-rga-printed
  span {
  font-weight: 600;
}
.multi-grid-page
  .card-view-wrapper
  .card-view
  .card-missing-paperwork
  > .rga-past-due {
  font-weight: 600;
}
.multi-grid-page
  .card-view-wrapper
  .card-view
  .card-missing-paperwork.m-top-40 {
  margin-top: 40px;
}
.card-view-wrapper .card-view .card-missing-paperwork.overDue {
  background-color: #facbcb;
}
.card-view-wrapper .card-view .card-missing-paperwork.isDue {
  background-color: #ffca82;
}
.card-view-wrapper .card-view .card-exchange-return {
  overflow: hidden;
  margin: 16px -30px 0 -30px;
  padding: 27px 30px 23px 30px;
  position: relative;
  height: auto;
  font-size: 16px;
  background-color: #f1f1f1;
  line-height: 22px;
}
.multi-grid-page
  .card-view-wrapper
  .card-view
  .card-exchange-return
  > .card-missing-paperwork-title {
  font-weight: 600;
  margin-bottom: 5px;
}
.multi-grid-page
  .card-view-wrapper
  .card-view
  .card-exchange-return
  > .rga-past-due {
  font-weight: 600;
}
.card-view-wrapper .card-view .card-exchange-return.m-top-40 {
  margin-top: 40px;
}
.card-view-wrapper .card-view .card-exchange-return.overDue {
  background-color: #facbcb;
}
.card-view-wrapper .card-view .card-exchange-return.isDue {
  background-color: #ffca82;
}

@media only screen and (max-width: 549px) {
  .card-view-wrapper .card-view .card-action {
    display: flex !important;
    position: absolute;
  }

  .card-view-wrapper .card-view .card-action .action-row {
    line-height: 35px !important;
  }

  .multi-grid-page
    .card-view-wrapper
    .card-view
    .card-action
    button:first-of-type {
    margin-right: 0px !important;
  }
}
.card-view-wrapper .card-view .card-action .action-row {
  margin-top: 10px;
  bottom: 25px;
  right: 29px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
}
.card-view-wrapper .card-view .card-action .more-options {
  font-size: 14px;
  font-weight: 700;
  color: #777777;
  margin-right: 15px;
  text-transform: uppercase;
  cursor: pointer;
}
.card-view-wrapper .card-view .card-action .add-to-list {
  color: #777777;
  font-size: 14px;
  font-weight: 700;
  margin-right: 15px;
  cursor: pointer;
}
.multi-grid-page
  .card-view-wrapper
  .card-view
  .card-action
  .add-to-list::before {
  content: "\F067";
  font-family: FontAwesome;
  margin-right: 10px;
  font-size: 10px;
}
.multi-grid-page
  .card-view-wrapper
  .card-view
  .card-action
  .add-to-list::after {
  content: "\F0D7";
  font-family: FontAwesome;
  margin-left: 10px;
  font-size: 16px;
}
.card-view-wrapper .card-view .card-action button {
  position: relative;
}
.multi-grid-page
  .card-view-wrapper
  .card-view
  .card-action
  button:first-of-type {
  margin-right: 16px;
}
.card-view-wrapper .card-view .card-action button.alternate {
  background-color: #dcdcdc;
  color: black;
}
.multi-grid-page
  .card-view-wrapper
  .card-view
  .card-action
  button.alternate:hover {
  background-color: #cccccc;
}
.card-view-wrapper .card-view .card-action button .loader {
  height: 18px;
  width: 18px;
  position: absolute;
  margin-top: 0px;
  margin-left: -12px;
}
.multi-grid-page
  .card-view-wrapper
  .card-view.no-action-buttons
  .card-ship-info {
  bottom: 30px;
}
.card-view-wrapper .card-view.is-service {
  height: 590px !important;
}
.legend-wrapper .legendLabel {
  float: left !important;
}
.legend-wrapper .legendPic {
  background: #fbcbcb;
  width: 15px;
  height: 15px;
  float: left;
  position: relative;
  margin-left: 15px;
  margin-top: 2px;
  margin-right: 5px;
}
.legend-wrapper .isDueLegend {
  background-color: #ffca82;
  width: 15px;
  height: 15px;
  margin-left: 15px;
  margin-top: 2px;
  margin-right: 5px;
  display: inline-block;
  margin-bottom: -2px;
}
.legend-wrapper .isDueLegend span {
  margin-left: 5px;
}

.legend-wrapper .formularyLegend {
  background-color: rgba(255, 204, 0, 0.25);
  width: 15px;
  height: 15px;
  margin-left: 15px;
  margin-top: 2px;
  margin-right: 5px;
  display: inline-block;
  margin-bottom: -2px;
}
.legend-wrapper .formularyLegend span {
  margin-left: 5px;
}
.checkbox-column .custom-checkbox-container {
  pointer-events: all;
}
.checkbox-column .custom-checkbox-container label {
  margin: 0;
  padding: 0;
  width: 25px;
}
.checkbox-column .custom-checkbox-container label span {
  margin: 0;
  padding: 0;
  top: -12px;
}
.checkbox-column .custom-checkbox-container label div {
  display: none;
}
@media (max-width: 549px) {
  .card-view-wrapper .card-view {
    display: flex;
    flex-direction: column;
    height: auto !important;
  }
  .card-view-wrapper .card-view .card-ship-info {
    margin-left: -40px;
    width: calc(180%);
    position: static !important;
    margin-top: 15px;
  }
  .card-view-wrapper .card-view .card-action {
    position: static !important;
    display: flex;
    flex-direction: column;
    margin-top: 15px;
  }
  .card-view-wrapper .card-view .card-action .action-row {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .card-view-wrapper .card-view .card-action .action-row span {
    margin-right: 0px;
  }
  .multi-grid-page
    .card-view-wrapper
    .card-view
    .card-action
    .action-row
    button:not(:first-child) {
    margin-top: 12px;
  }
}
@media (max-width: 1440px) {
  .card-view-wrapper .card-view {
    width: calc(50% - 15px);
  }
  .card-view-wrapper .card-view:nth-child(3n) {
    margin-right: 30px;
  }
  .card-view-wrapper .card-view:nth-child(2n) {
    margin-right: 0;
  }
}
@media (max-width: 1149px) {
  .card-view-wrapper .card-view {
    width: calc(100%);
    margin-right: 0;
  }
  .card-view-wrapper .card-view:nth-child(3n) {
    margin-right: 0;
  }
  .card-view-wrapper .card-view:nth-child(2n) {
    margin-right: 0;
  }
  .card-view-wrapper .card-view .part-info .detail {
    margin-top: 0;
  }
  .card-view-wrapper .card-view .card-ship-info {
    padding: 0 29px 0;
    bottom: 70px;
  }
  .card-view-wrapper .card-view .card-ship-info .StepProgress {
    font-size: 5px;
  }
}
.loading-data {
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  text-align: center;
  width: 100%;
  height: 100%;
  z-index: 990;
  margin-top: 5px;
}
.loading-data.dashboard {
  height: auto;
  margin-top: -130px;
}
.loading-data.report {
  height: calc(-30%);
  margin-top: -130px;
}
.loading-data .message {
  font-size: 22px;
  font-weight: 300;
}
.loading-data .loader {
  left: calc(12.5%) !important;
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid #ff9505;
  width: 75px;
  height: 75px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  margin-top: 30px;
}
@-webkit-keyframes spin {
  .loading-data 0% {
    -webkit-transform: rotate(0deg);
  }
  .loading-data 100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  .loading-data 0% {
    transform: rotate(0deg);
  }
  .loading-data 100% {
    transform: rotate(360deg);
  }
}
.loading-dashboard .loader {
  left: calc(12.5%) !important;
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid #ff9505;
  width: 75px;
  height: 75px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  position: absolute;
  top: 150px;
}

@-webkit-keyframes spin {
  .loading-dashboard 0% {
    -webkit-transform: rotate(0deg);
  }
  .loading-dashboard 100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  .loading-dashboard 0% {
    transform: rotate(0deg);
  }
  .loading-dashboard 100% {
    transform: rotate(360deg);
  }
}
.company-selection-popup .selection-title {
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 30px;
}
.company-selection-popup .column-checkbox {
  width: 50%;
  display: inline-block;
  font-size: 16px;
}
.company-selection-popup .column-checkbox .custom-checkbox-container {
  font-size: 16px;
}
:not(.dashboard) .StepProgress {
  position: relative;
  padding-left: 40px;
  list-style: none;
  margin-top: 20px;
  margin-bottom: 0;
}
:not(.dashboard) .StepProgress-item {
  position: relative;
  counter-increment: list;
  padding-bottom: 20px;
  font-size: 16px;
}
:not(.dashboard) .StepProgress-item::before {
  display: inline-block;
  content: "";
  position: absolute;
  left: -30px;
  height: 100%;
  width: 10px;
  top: 4px;
}
:not(.dashboard) .StepProgress-item::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: -24px;
  width: 10px;
  height: 10px;
  border: 2px solid #005da6;
  border-radius: 50%;
  background-color: #fff;
  top: 4px;
}
:not(.dashboard) .StepProgress-item.current::before {
  border-left: 4px solid #005da6;
}
:not(.dashboard) .StepProgress-item.current::after {
  content: "";
  font-size: 10px;
  color: #fff;
  text-align: center;
  border: 2px solid #005da6;
  background-color: #005da6;
  left: -36px;
  width: 16px;
  height: 16px;
}
:not(.dashboard) .StepProgress-item.is-done::before {
  border-left: 4px solid #005da6;
}
:not(.dashboard) .StepProgress-item.is-done::after {
  content: "";
  font-size: 10px;
  color: #fff;
  text-align: center;
  border: 2px solid #005da6;
  background-color: #005da6;
}
:not(.dashboard) .StepProgress-item.is-next::before {
  border-left: 2px solid #cccccc;
  left: -29px;
}
:not(.dashboard) .StepProgress-item.is-next::after {
  content: "";
  font-size: 10px;
  color: #fff;
  text-align: center;
  border: 2px solid #cccccc;
  background-color: #f1f1f1;
  left: -41px;
  width: 26px;
  height: 26px;
}
:not(.dashboard) .StepProgress-item.is-next.complete::before {
  border-left: 4px solid #005da6;
  left: -30px;
}
:not(.dashboard) .StepProgress-item.is-next.complete::after {
  background-color: #005da6;
  border: 2px solid #005da6;
}
:not(.dashboard) .StepProgress li span {
  display: block;
}
:not(.dashboard) .StepProgress li span:nth-of-type(1) {
  color: #949494;
}
:not(.dashboard) .StepProgress li span:nth-of-type(2) {
  font-weight: 600;
}
:not(.dashboard) .StepProgress li span:nth-of-type(3) {
  font-style: italic;
}
:not(.dashboard) .StepProgress .eta-info {
  font-size: 16px;
  color: black;
}
:not(.dashboard) .StepProgress .eta-info .delivered {
  font-size: 22px;
  font-weight: 400;
}
:not(.dashboard) .StepProgress .eta-info .eta {
  font-weight: 700;
}
:not(.dashboard) .StepProgress .eta-info .eta .eta-date {
  font-size: 22px;
  font-weight: 400;
  display: inline-block;
  margin-left: 5px;
}
:not(.dashboard) .StepProgress .eta-info .eta-desc {
  margin-top: 5px;
}
:not(.dashboard) .StepProgress .eta-info .eta-line-3 {
  font-style: italic;
  margin-top: 5px;
}
:not(.dashboard) .StepProgress .eta-info a {
  color: #005da6;
  text-decoration: none;
  margin-top: 10px;
  display: inline-block;
  font-weight: 700;
}
:not(.dashboard) .StepProgress .order-status {
  font-size: 16px;
}
:not(.dashboard) .StepProgress .order-status .status {
  font-weight: 700;
}
:not(.dashboard) .StepProgress .order-status .status a {
  color: #005da6;
  text-decoration: none;
  margin-left: 5px;
}
:not(.dashboard) .StepProgress .order-status .ship-method {
  margin-top: 5px;
}

@media (min-width: 1150px) and (max-width: 1629px) {
  .ps-hidden-lg {
    display: none !important;
  }
}

@media (min-width: 820px) and (max-width: 1149px) {
  .ps-hidden-md {
    display: none !important;
  }
}

@media (min-width: 1630px) {
  .ps-hidden-xl {
    display: none !important;
  }
}

@media (min-width: 1149px) {
  .card-view-wrapper .card-view .part-info .detail {
    margin-bottom: 4px;
  }
}

@media (max-width: 549px) {
  .ps-hidden-xs {
    display: none !important;
  }
  .mobile-search {
    width: 100% !important;
  }
}

@media (max-width: 450px) {
  .part-info {
    width: 100%;
    display: block !important;
    max-width: unset;
  }
}

@media (max-width: 1149px) {
  .grid-bottom > div:nth-child(3) {
    overflow-x: scroll;
  }

  .multi-grid-page--sticky hr {
    margin-bottom: 0px;
  }
}

@media (max-width: 819px) {
  .ps-hidden-sm {
    display: none !important;
  }

  .mobile-display {
    margin-bottom: 5px;
    margin-left: 10px;
  }

  .multi-grid-page--sticky hr {
    margin-bottom: 0px;
  }
}

.switch-checkbox {
  height: "0.7rem";
}
.filter #rgaTypeFilterDropdown {
    padding-top: 8px;
   
}
.filter > :first-child{
    height:35px;
}

.top-row {
  display: inline-flex;
  justify-content: space-between;
  font-family: "Source Sans Pro";
  letter-spacing: 0px;
  margin-bottom: 20px;
  margin-top: 27px;
}

.top-row .back-text {
  color: rgb(84, 84, 84);
  font-size: 14px;
  cursor: pointer;
  font-weight: normal;
}

.top-row .back-text .fa-chevron-left {
  padding-right: 10px;
  font-size: 10px;
  height: 14px;
  width: 14px;
  font-weight: 300;
}

.top-row .cancel-quote-text {
  color: rgb(0, 0, 0);
  font-size: 16px;
  font-weight: normal;
}
[role=button],input[type=button],input[type=submit] {
    cursor: pointer
}
