.container-fluid {
  padding: 0 35px;
}

.p-component {
    font-family: "Source Sans Pro", sans-serif;
}

.row {
  margin: 0 -35px;
  padding: 0 35px;
}
.search-criteria {
  margin: 20px 0 20px 0;
}
.search-criteria_row {
  padding: 15px 0px 15px 0px !important;
  background-color: #f1f1f1;
  display: flex;
  align-items: center;
  position: relative;
}
.search-criteria_row--delete {
  position: absolute;
  right: 10px;
}
@media (min-width: 768px) and (max-width: 819px) {
  .search-criteria_row .col-md-6:not(:first-child) {
    margin-top: 0px;
    padding-left: 10px;
  }
}
@media (max-width: 819px) {
  .search-criteria_row {
    padding: 7px 33px 7px 10px !important;
  }
  .search-criteria_row--delete {
    position: absolute;
    right: 8px;
    bottom: 10px;
  }
  .search-criteria_row_types {
    padding-left: 0px;
  }
  .search-criteria_row_view {
    padding-left: 0px;
    padding-right: 0px;
  }
  .search-criteria_row .col-md-6 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .search-criteria_row .col-xs-12,
  .search-criteria_row .col-sm-12,
  .search-criteria_row .col-md-12 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .search-criteria_row .col-xs-12:not(:first-child),
  .search-criteria_row .col-sm-12:not(:first-child),
  .search-criteria_row .col-md-12:not(:first-child) {
    margin-top: 8px;
  }
  .search-criteria_row:not(:first-child) {
    margin-top: 8px;
  }
}
@media (max-width: 819px) and (max-width: 767px) {
  .search-criteria_row .col-md-6:not(:first-child) {
    margin-top: 8px;
  }
}
@media (min-width: 820px) {
  .search-criteria_row {
    padding: 7px 20px 7px 10px !important;
  }
  .search-criteria_row:not(:first-child) {
    margin-top: 12px;
  }
}
.search-criteria_row div:last-child i {
  font-size: 22px;
}
.search-criteria_worow {
  padding: 15px 0px 15px 0px !important;
  display: flex;
  align-items: center;
}
.search-criteria_worow div:last-child i {
  font-size: 22px;
}
.search-criteria_buttons {
  padding: 0px !important;
  margin: 20px 0px 20px 0px !important;
  padding-left: 0px !important;
}
.search-criteria_buttons .button:first-child {
  margin-right: 10px;
}
.search-criteria_buttons a {
  background-color: #ffffff !important;
  color: #949494 !important;
  width: 140px;
  margin-left: 0px;
  height: 48px;
  line-height: 48px;
  padding-left: 0px;
}
.search-criteria .field {
  margin-top: 0px !important;
  display: flex;
  align-items: center;
  height: 100%;
}
.search-criteria .ui-calendar {
  margin-top: 0px !important;
}
.search-criteria .custom-multi-select {
  margin-top: 0;
}

.search-crteria_row_view .p-inputwrapper-filled {
    width: 100% !important;
}

.p-inputwrapper-filled .p-button-icon-only {
    margin-right: 0;
}

@media (max-width: 819px) {
    .p-inputwrapper-filled {
        width: 100%;
        height: 50px;
    }
}

@media (min-width: 819px){
    .p-inputwrapper-filled {
        width: 285px;
        height: 50px;   
    }
}

@media (min-width: 768px) and (max-width: 819px) {
    .mobile-row {
        width: 100%;
        margin: 0;
        padding: 0;
    }

    .search-crteria_row_types,
    .search-crteria_row_view {
        max-width: 100%;
        flex: 0 0 100%;
    }
}

@media (min-width: 820px) and (max-width: 1149px) {
    .col-md-6 {
        width: 50%;
        max-width: 50%;
        flex: 0 0 50%;        
    }

    .p-inputwrapper-filled {
        width: 100%;
    }
}
