@charset "UTF-8";
.p-widget {
  font-family: "Roboto", "Trebuchet MS", Arial, Helvetica, sans-serif;
  font-size: 1em;
}
.p-widget input,
.p-widget select,
.p-widget textarea,
.p-widget button {
  font-family: "Roboto", "Trebuchet MS", Arial, Helvetica, sans-serif;
  font-size: 1em;
}
.p-widget :active {
  outline: none;
}
.p-widget-content {
  border: 1px solid #d5d5d5;
  background: #ffffff;
  color: #222222;
}
.p-widget-content a {
  color: #222222;
}
.p-widget-header {
  border: 1px solid #d9d9d9;
  background: #f6f7f9;
  color: #1b1d1f;
  font-weight: normal;
}
.p-widget-header a {
  color: #1b1d1f;
}
.p-widget-overlay {
  background: #666666;
  opacity: 0.5;
  filter: Alpha(Opacity=50);
}
.p-state-default {
  border: 1px solid #d6d6d6;
  background: #ffffff;
  color: #555555;
}
.p-state-default a {
  color: #555555;
}
.p-state-active {
  border-color: #bebebe;
  background: #d6d6d6;
  color: #212121;
}
.p-state-active a {
  color: #212121;
}
.p-state-highlight {
  border-color: #156090;
  background: #186ba0;
  color: #ffffff;
}
.p-state-highlight a {
  color: #ffffff;
}
.p-state-focus {
  border-color: #c0c0c0;
  background: #eeeeee;
  color: #212121;
}
.p-state-focus a {
  color: #212121;
}
.p-state-error {
  border-color: #f44336;
  background: #f5554a;
  color: #cd0a0a;
}
.p-state-error a {
  color: #cd0a0a;
}
.p-state-disabled,
.p-widget:disabled {
  opacity: 0.35;
  filter: Alpha(Opacity=35);
  background-image: none;
  cursor: default !important;
}
.p-state-disabled *,
.p-widget:disabled * {
  cursor: default !important;
}
.p-inputtext {
  background: #ffffff;
  color: #222222;
}
.p-inputtext:enabled:hover {
  border-color: #c0c0c0;
}
.p-inputtext.p-state-focus,
.p-inputtext:focus {
  outline: 0 none;
  border-color: #c0c0c0;
  -moz-box-shadow: 0px 0px 5px #c0c0c0;
  -webkit-box-shadow: 0px 0px 5px #c0c0c0;
  box-shadow: 0px 0px 5px #c0c0c0;
}
.p-inputgroup .p-inputgroup-addon {
  border-color: #d6d6d6;
  background-color: #f0f0f0;
  color: #222222;
}
.p-inputgroup .p-inputgroup-addon:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.p-inputgroup .p-inputgroup-addon:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.p-inputgroup .p-button:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.p-inputgroup .p-button:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.p-float-label input.ng-dirty.ng-invalid ~ label {
  color: #cd0a0a;
}
.p-autocomplete
  .p-autocomplete-multiple-container:not(.p-state-disabled):hover {
  border-color: #c0c0c0;
}
.p-autocomplete
  .p-autocomplete-multiple-container:not(.p-state-disabled).p-state-focus {
  border-color: #c0c0c0;
}
.p-autocomplete-panel .p-autocomplete-list-item:hover {
  border-color: #156090;
  background: #186ba0;
  color: #ffffff;
}
.p-autocomplete-panel .p-autocomplete-list-item:hover a {
  color: #ffffff;
}
.p-chips > ul:not(.p-state-disabled):hover {
  border-color: #c0c0c0;
}
.p-chips > ul:not(.p-state-disabled).p-state-focus {
  border-color: #c0c0c0;
}
.p-button:focus,
.p-button:enabled:hover,
.p-fileupload-choose:not(.p-state-disabled):hover {
  outline: 0 none;
  border-color: #c0c0c0;
  background: #eeeeee;
  color: #212121;
}
.p-button:focus a,
.p-button:enabled:hover a,
.p-fileupload-choose:not(.p-state-disabled):hover a {
  color: #212121;
}
.p-button:enabled:active,
.p-fileupload-choose:not(.p-state-disabled):active {
  border-color: #bebebe;
  background: #d6d6d6;
  color: #212121;
}
.p-chkbox-box:not(.p-state-disabled):not(.p-state-active):hover {
  border-color: #c0c0c0;
  background: #eeeeee;
  color: #212121;
}
.p-chkbox-box:not(.p-state-disabled):not(.p-state-active):hover a {
  color: #212121;
}
.p-radiobutton-box:not(.p-state-disabled):not(.p-state-active):hover {
  border-color: #c0c0c0;
  background: #eeeeee;
  color: #212121;
}
.p-radiobutton-box:not(.p-state-disabled):not(.p-state-active):hover a {
  color: #212121;
}
.p-dropdown:not(.p-state-disabled):hover {
  border-color: #c0c0c0;
  background: #eeeeee;
  color: #212121;
}
.p-dropdown:not(.p-state-disabled):hover a {
  color: #212121;
}
.p-dropdown-panel .p-dropdown-item:not(.p-state-highlight):hover {
  border-color: #c0c0c0;
  background: #eeeeee;
  color: #212121;
}
.p-dropdown-panel .p-dropdown-item:not(.p-state-highlight):hover a {
  color: #212121;
}
.p-listbox .p-listbox-header .p-listbox-filter-container .fa {
  color: #222222;
}
.p-listbox:not(.p-state-disabled)
  .p-listbox-item:not(.p-state-highlight):hover {
  border-color: #c0c0c0;
  background: #eeeeee;
  color: #212121;
}
.p-listbox:not(.p-state-disabled)
  .p-listbox-item:not(.p-state-highlight):hover
  a {
  color: #212121;
}
.p-listbox.p-state-disabled .p-chkbox-box:not(.p-state-active):hover {
  border-color: #d6d6d6;
  background: #ffffff;
  color: #555555;
}
.p-multiselect:not(.p-state-disabled):hover {
  border-color: #c0c0c0;
  background: #eeeeee;
  color: #212121;
}
.p-multiselect:not(.p-state-disabled):hover a {
  color: #212121;
}
.p-multiselect-panel .p-multiselect-item:not(.p-state-highlight):hover {
  border-color: #c0c0c0;
  background: #eeeeee;
  color: #212121;
}
.p-multiselect-panel .p-multiselect-item:not(.p-state-highlight):hover a {
  color: #212121;
}
.p-multiselect-panel .p-multiselect-close {
  color: #1b1d1f;
}
.p-multiselect-panel .p-multiselect-filter-container .fa {
  color: #222222;
}
.p-spinner:not(.p-state-disabled) .p-spinner-button:enabled:hover {
  border-color: #c0c0c0;
  background: #eeeeee;
  color: #212121;
}
.p-spinner:not(.p-state-disabled) .p-spinner-button:enabled:hover a {
  color: #212121;
}
.p-spinner:not(.p-state-disabled) .p-spinner-button:enabled:active {
  border-color: #bebebe;
  background: #d6d6d6;
  color: #212121;
}
.p-selectbutton .p-button:not(.p-state-disabled):not(.p-state-active):hover {
  border-color: #c0c0c0;
  background: #eeeeee;
  color: #212121;
}
.p-selectbutton .p-button:not(.p-state-disabled):not(.p-state-active):hover a {
  color: #212121;
}
.p-togglebutton:not(.p-state-disabled):not(.p-state-active):hover {
  border-color: #c0c0c0;
  background: #eeeeee;
  color: #212121;
}
.p-togglebutton:not(.p-state-disabled):not(.p-state-active):hover a {
  color: #212121;
}
.p-paginator a:not(.p-state-disabled):not(.p-state-active):hover {
  border-color: #c0c0c0;
  background: #eeeeee;
  color: #212121;
}
.p-paginator a:not(.p-state-disabled):not(.p-state-active):hover a {
  color: #212121;
}
.p-paginator a {
  color: #555555;
}
.p-datatable .p-rowgroup-header a {
  color: #1b1d1f;
}
.p-datatable .p-sortable-column:not(.p-state-active):hover {
  background: #eeeeee;
  color: #212121;
}
.p-datatable .p-row-toggler {
  color: #222222;
}
.p-datatable
  tbody.p-datatable-hoverable-rows
  > tr.p-widget-content:not(.p-state-highlight):hover {
  cursor: pointer;
  background: #eeeeee;
  color: #212121;
}
.p-datatable .p-datatable-data > tr.p-datatable-dragpoint-top > td {
  box-shadow: inset 0 2px 0 0 #186ba0;
}
.p-datatable .p-datatable-data > tr.p-datatable-dragpoint-bottom > td {
  box-shadow: inset 0 -2px 0 0 #186ba0;
}
.p-orderlist .p-orderlist-item:not(.p-state-highlight):hover {
  border-color: #c0c0c0;
  background: #eeeeee;
  color: #212121;
}
.p-orderlist .p-orderlist-item:not(.p-state-highlight):hover a {
  color: #212121;
}
.p-picklist .p-picklist-item:not(.p-state-highlight):hover {
  border-color: #c0c0c0;
  background: #eeeeee;
  color: #212121;
}
.p-picklist .p-picklist-item:not(.p-state-highlight):hover a {
  color: #212121;
}
.p-picklist .p-picklist-droppoint-highlight {
  border-color: #156090;
  background: #186ba0;
  color: #1f1f1f;
}
.p-picklist .p-picklist-droppoint-highlight a {
  color: #1f1f1f;
}
.p-picklist .p-picklist-highlight {
  border-color: #156090;
  color: #1f1f1f;
}
.p-picklist .p-picklist-highlight a {
  color: #1f1f1f;
}
.p-tree.p-treenode-dragover {
  border-color: #156090;
}
.p-tree
  .p-treenode-content.p-treenode-selectable
  .p-treenode-label:not(.p-state-highlight):hover {
  border-color: #c0c0c0;
  background: #eeeeee;
  color: #212121;
}
.p-tree
  .p-treenode-content.p-treenode-selectable
  .p-treenode-label:not(.p-state-highlight):hover
  a {
  color: #212121;
}
.p-tree .p-treenode-content.p-treenode-dragover {
  background: #d6d6d6;
  color: #212121;
}
.p-tree.p-tree-horizontal
  .p-treenode-content.p-treenode-selectable
  .p-treenode-label:not(.p-state-highlight):hover {
  background-color: inherit;
  color: inherit;
}
.p-tree.p-tree-horizontal
  .p-treenode-content.p-treenode-selectable:not(.p-state-highlight):hover {
  border-color: #c0c0c0;
  background: #eeeeee;
  color: #212121;
}
.p-tree.p-tree-horizontal
  .p-treenode-content.p-treenode-selectable:not(.p-state-highlight):hover
  a {
  color: #212121;
}
.p-treetable
  .p-treetable-row.p-treetable-row-selectable:not(.p-state-highlight):hover {
  background: #eeeeee;
  color: #212121;
}
.p-treetable .p-sortable-column:not(.p-state-active):hover {
  background: #eeeeee;
  color: #212121;
}
.p-organizationchart
  .p-organizationchart-node-content.p-organizationchart-selectable-node:not(.p-state-highlight):hover {
  border-color: #c0c0c0;
  background: #eeeeee;
  color: #212121;
}
.p-organizationchart
  .p-organizationchart-node-content.p-organizationchart-selectable-node:not(.p-state-highlight):hover
  a {
  color: #212121;
}
.p-accordion
  .p-accordion-header:not(.p-state-active):not(.p-state-disabled):hover {
  border-color: #c0c0c0;
  background: #eeeeee;
  color: #212121;
}
.p-accordion
  .p-accordion-header:not(.p-state-active):not(.p-state-disabled):hover
  a {
  color: #212121;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend:hover {
  border-color: #c0c0c0;
  background: #eeeeee;
  color: #212121;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend:hover a {
  color: #212121;
}
.p-panel .p-panel-titlebar .p-panel-titlebar-icon:hover {
  border-color: #c0c0c0;
  background: #eeeeee;
  color: #212121;
}
.p-panel .p-panel-titlebar .p-panel-titlebar-icon:hover a {
  color: #212121;
}
.p-tabview .p-tabview-nav li:not(.p-state-active):not(.p-state-disabled):hover {
  border-color: #c0c0c0;
  background: #eeeeee;
  color: #212121;
}
.p-tabview
  .p-tabview-nav
  li:not(.p-state-active):not(.p-state-disabled):hover
  a {
  color: #212121;
}
.p-dialog .p-dialog-titlebar-icon {
  color: #1b1d1f;
}
.p-dialog .p-dialog-titlebar-icon:hover {
  border-color: #c0c0c0;
  background: #eeeeee;
  color: #212121;
}
.p-dialog .p-dialog-titlebar-icon:hover a {
  color: #212121;
}
.p-sidebar .p-sidebar-close {
  color: #1b1d1f;
}
.p-sidebar .p-sidebar-close:hover {
  border-color: #c0c0c0;
  background: #eeeeee;
  color: #212121;
}
.p-sidebar .p-sidebar-close:hover a {
  color: #212121;
}
.p-overlaypanel .p-overlaypanel-close:hover {
  border-color: #c0c0c0;
  background: #eeeeee;
  color: #212121;
}
.p-overlaypanel .p-overlaypanel-close:hover a {
  color: #212121;
}
.p-inplace .p-inplace-display:hover {
  border-color: #c0c0c0;
  background: #eeeeee;
  color: #212121;
}
.p-inplace .p-inplace-display:hover a {
  color: #212121;
}
.p-breadcrumb a {
  color: #1b1d1f;
}
.p-menu .p-menuitem .p-menuitem-link {
  color: #222222;
}
.p-menu .p-menuitem .p-menuitem-link:hover {
  border-color: #c0c0c0;
  background: #eeeeee;
  color: #212121;
  border-color: transparent;
}
.p-menu .p-menuitem .p-menuitem-link:hover a {
  color: #212121;
}
.p-menu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  border-color: #c0c0c0;
  background: #eeeeee;
  color: #212121;
  border-color: transparent;
}
.p-menu .p-menuitem.p-menuitem-active > .p-menuitem-link a {
  color: #212121;
}
.p-tabmenu .p-tabmenu-nav li:not(.p-state-active):hover {
  border-color: #c0c0c0;
  background: #eeeeee;
  color: #212121;
}
.p-tabmenu .p-tabmenu-nav li:not(.p-state-active):hover a {
  color: #212121;
}
.p-steps .p-steps-item:not(.p-state-highlight):not(.p-state-disabled):hover {
  border-color: #c0c0c0;
  background: #eeeeee;
  color: #212121;
}
.p-steps .p-steps-item:not(.p-state-highlight):not(.p-state-disabled):hover a {
  color: #212121;
}
.p-panelmenu .p-panelmenu-header:not(.p-state-active):hover {
  border-color: #c0c0c0;
  background: #eeeeee;
  color: #212121;
  border-color: #d6d6d6;
}
.p-panelmenu .p-panelmenu-header:not(.p-state-active):hover a {
  color: #212121;
}
.p-panelmenu .p-panelmenu-header:not(.p-state-active):hover a {
  color: #212121;
}
.p-panelmenu .p-panelmenu-header.p-state-active a {
  color: #212121;
}
.p-panelmenu .p-panelmenu-content .p-menuitem-link {
  color: #222222;
}
.p-panelmenu .p-panelmenu-content .p-menuitem-link:hover {
  border-color: #c0c0c0;
  background: #eeeeee;
  color: #212121;
  border-color: transparent;
}
.p-panelmenu .p-panelmenu-content .p-menuitem-link:hover a {
  color: #212121;
}
.p-datepicker .p-datepicker-header a {
  color: #1b1d1f;
}
.p-datepicker .p-datepicker-header a:hover {
  border-color: #c0c0c0;
  background: #eeeeee;
  color: #212121;
}
.p-datepicker .p-datepicker-header a:hover a {
  color: #212121;
}
.p-datepicker
  .p-datepicker-calendar
  tbody
  > tr
  .p-datepicker-selectable-other-month
  a:hover {
  border-color: #c0c0c0;
  background: #eeeeee;
  color: #212121;
}
.p-datepicker
  .p-datepicker-calendar
  tbody
  > tr
  .p-datepicker-selectable-other-month
  a:hover
  a {
  color: #212121;
}
.p-datepicker .p-datepicker-calendar td:not(.p-state-disabled) a:hover {
  border-color: #c0c0c0;
  background: #eeeeee;
  color: #212121;
}
.p-datepicker .p-datepicker-calendar td:not(.p-state-disabled) a:hover a {
  color: #212121;
}
.fc .fc-toolbar .fc-prev-button .p-icon-circle-triangle-w {
  margin-top: 0.3em;
  background: none !important;
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-indent: 0px !important;
  text-align: center;
}
.fc .fc-toolbar .fc-prev-button .p-icon-circle-triangle-w:before {
  content: "";
}
.fc .fc-toolbar .fc-next-button .p-icon-circle-triangle-e {
  margin-top: 0.3em;
  background: none !important;
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-indent: 0px !important;
  text-align: center;
}
.fc .fc-toolbar .fc-next-button .p-icon-circle-triangle-e:before {
  content: "";
}
.p-rating a {
  color: #222222;
}
.p-organizationchart .p-organizationchart-line-down {
  background-color: #bcbcbc;
}
.p-organizationchart .p-organizationchart-line-left {
  border-right: 1px solid #bcbcbc;
}
.p-organizationchart .p-organizationchart-line-top {
  border-top: 1px solid #bcbcbc;
}
.p-organizationchart .p-organizationchart-node-content {
  border-color: #bcbcbc;
}
.p-organizationchart .p-organizationchart-node-content .p-node-toggler {
  color: #bcbcbc;
}
.p-inputtext.p-state-error {
  border-bottom-color: #f44336;
}
.p-corner-tl {
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
}
.p-corner-tr {
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
}
.p-corner-bl {
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.p-corner-br {
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.p-corner-top {
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
}
.p-corner-bottom {
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.p-corner-right {
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.p-corner-left {
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.p-corner-all {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
}
.p-widget-header {
  background: #f6f7f9 0 0 repeat-x;
  background: -moz-linear-gradient(top, #f6f7f9 0%, #ebedf0 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #f6f7f9),
    color-stop(100%, #ebedf0)
  );
  background: -webkit-linear-gradient(top, #f6f7f9 0%, #ebedf0 100%);
  background: -o-linear-gradient(top, #f6f7f9 0%, #ebedf0 100%);
  background: -ms-linear-gradient(top, #f6f7f9 0%, #ebedf0 100%);
  background: linear-gradient(to bottom, #f6f7f9 0%, #ebedf0 100%);
}
.p-accordion .p-accordion-header {
  background: #f6f7f9;
  border-top: 1px solid #d9d9d9;
}
.p-accordion .p-accordion-header a {
  color: #1b1d1f;
}
.p-accordion
  .p-accordion-header:not(.p-state-active):not(.p-state-disabled):hover {
  background: #ededf0;
}
.p-accordion .p-accordion-header.p-state-active {
  background: #ffffff;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.p-accordion .p-accordion-content {
  border: 0 none;
}
.p-tabview.p-widget-content {
  border: 0 none;
}
.p-tabview .p-tabview-nav {
  background: transparent;
}
.p-tabview .p-tabview-nav > li.p-state-default {
  background: #f6f7f9;
}
.p-tabview .p-tabview-nav > li.p-state-active {
  background: #ffffff;
  font-weight: normal;
  color: #555555;
}
.p-tabview
  .p-tabview-nav
  > li:not(.p-state-active):not(.p-state-disabled):hover {
  background: #ededf0;
}
.p-spinner:not(.p-state-disabled) .p-spinner-button:enabled:hover {
  border: 1px solid #1f89ce;
  background: #1f89ce;
  outline: 0 none;
  color: #ffffff;
}
.p-spinner:not(.p-state-disabled) .p-spinner-button:enabled:active {
  border: 1px solid #156090;
  background: #186ba0;
  color: #ffffff;
}
.p-slider {
  position: relative;
  text-align: left;
  background: #838688;
  border: none;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.6) inset;
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.6) inset;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.6) inset;
}
.p-slider .p-slider-handle {
  position: absolute;
  z-index: 2;
  width: 17px !important;
  height: 21px !important;
  cursor: default;
  /* background: url("images/slider_handles.png") 0 0 no-repeat; */
  outline: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border: none;
}
.p-slider .p-slider-range {
  position: absolute;
  z-index: 1;
  font-size: 0.7em;
  display: block;
  border: 0;
  background: #14a4ff;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.6) inset;
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.6) inset;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.6) inset;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.p-slider .p-slider-handle.p-state-active {
  background-position: -17px 0;
}
.p-slider-horizontal {
  height: 6px;
}
.p-slider-horizontal .p-slider-handle {
  top: -2px !important;
  margin-left: -0.6em;
}
.p-slider-horizontal .p-slider-range {
  top: 0;
  height: 100%;
}
.p-slider-horizontal .p-slider-range-min {
  left: 0;
}
.p-slider-horizontal .p-slider-range-max {
  right: 0;
}
.p-slider-vertical {
  width: 0.8em;
  height: 100px;
}
.p-slider-vertical .p-slider-handle {
  left: -0.1em !important;
  margin-left: 0;
  margin-bottom: -0.6em;
}
.p-slider-vertical .p-slider-range {
  left: 0;
  width: 100%;
}
.p-slider-vertical .p-slider-range-min {
  bottom: 0;
}
.p-slider-vertical .p-slider-range-max {
  top: 0;
}
.p-progressbar .p-progressbar-value {
  border: 1px solid #3f94e9;
  background: #8ec5fc;
}
.p-progressbar .p-progressbar-label {
  color: #222222;
}
.p-widget-header .p-button,
.p-widget-content .p-button,
.p-widget.p-button,
.p-button {
  border: 1px solid #2399e5;
  color: #ffffff;
  background: #2399e5;
  -webkit-transition: background-color 0.2s;
  -moz-transition: background-color 0.2s;
  transition: background-color 0.2s;
}
.p-widget-header .p-button:enabled:hover,
.p-widget-header .p-button:focus,
.p-widget-content .p-button:enabled:hover,
.p-widget-content .p-button:focus,
.p-widget.p-button:enabled:hover,
.p-widget.p-button:focus,
.p-button:enabled:hover,
.p-button:focus {
  border: 1px solid #1f89ce;
  background: #1f89ce;
  outline: 0 none;
  color: #ffffff;
}
.p-widget-header .p-button:enabled:active,
.p-widget-content .p-button:enabled:active,
.p-widget.p-button:enabled:active,
.p-button:enabled:active {
  border: 1px solid #156090;
  background: #186ba0;
  color: #ffffff;
}
.p-fileupload-choose:not(.p-state-disabled):hover,
.p-fileupload-choose.p-state-focus {
  border: 1px solid #1f89ce;
  background: #1f89ce;
  outline: 0 none;
  color: #ffffff;
}
.p-fileupload-choose:not(.p-state-disabled):active {
  border: 1px solid #156090;
  background: #186ba0;
  color: #ffffff;
}
.p-chkbox-box.p-state-active,
.p-radiobutton-box.p-state-active {
  border: 1px solid #156090;
  background: #186ba0;
  color: #ffffff;
}
.p-chkbox-box.p-state-focus,
.p-radiobutton-box.p-state-focus {
  -moz-box-shadow: 0px 0px 5px #1f89ce;
  -webkit-box-shadow: 0px 0px 5px #1f89ce;
  box-shadow: 0px 0px 5px #1f89ce;
}
.p-chkbox-box.p-state-focus.p-state-active {
  background: #186ba0;
}
.p-inputtext {
  background: #ffffff;
  color: #222222;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  transition: 0.2s;
}
.p-inputtext.p-state-focus,
.p-inputtext:focus {
  -moz-box-shadow: 0px 0px 5px #1f89ce;
  -webkit-box-shadow: 0px 0px 5px #1f89ce;
  box-shadow: 0px 0px 5px #1f89ce;
}
.p-inputswitch-on {
  background: #186ba0 !important;
  color: #ffffff !important;
}
.p-paginator .p-paginator-page.p-state-active {
  background: #186ba0;
  color: #ffffff;
  border-color: #156090;
}
.p-datatable th.p-state-default {
  background: #ebedf0;
  border-color: #d9d9d9;
}
.p-datatable th.p-sortable-column:not(.p-state-active):hover {
  background: #d3d5d8;
  border-color: #d9d9d9;
}
.p-datatable th.p-state-active {
  background: #186ba0;
  color: #ffffff;
}
.p-datatable tbody > tr.p-widget-content {
  border-color: #d9d9d9;
}
.p-datatable tbody > tr.p-widget-content.p-datatable-odd {
  background-color: #fafafb;
}
.p-datatable tbody > tr.p-widget-content.p-state-highlight {
  background-color: #186ba0;
  color: #ffffff;
}
.p-datatable tfoot td.p-state-default {
  background: #ebedf0;
  border-color: #d9d9d9;
}
.p-panel.p-widget {
  padding: 0;
}
.p-panel.p-widget .p-panel-titlebar.p-corner-all {
  -moz-border-radius-bottom-left: 0;
  -webkit-border-bottom-left-radius: 0;
  border-bottom-left-radius: 0;
  -moz-border-radius-bottom-right: 0;
  -webkit-border-bottom-right-radius: 0;
  border-bottom-right-radius: 0;
}
.p-panel.p-widget .p-panel-titlebar {
  border-width: 0 0 1px 0;
}
.p-panel.p-widget .p-panel-titlebar-icon span {
  position: relative;
  top: 1px;
}
.p-treetable th.p-state-default {
  background: #ebedf0;
  border-color: #d9d9d9;
}
.p-treetable th.p-sortable-column:not(.p-state-active):hover {
  background: #d3d5d8;
  border-color: #d9d9d9;
}
.p-treetable th.p-state-active {
  background: #186ba0;
  color: #ffffff;
}
.p-togglebutton.p-button.p-state-default,
.p-selectbutton .p-button.p-state-default {
  border: 1px solid #d6d6d6;
  background: #ffffff;
  font-weight: normal;
  color: #555555;
}
.p-togglebutton.p-button.p-state-hover,
.p-togglebutton.p-button.p-state-focus,
.p-selectbutton .p-button.p-state-hover,
.p-selectbutton .p-button.p-state-focus {
  border: 1px solid #c0c0c0;
  background: #eeeeee;
  font-weight: normal;
  color: #212121;
}
.p-togglebutton.p-button.p-state-focus,
.p-selectbutton .p-button.p-state-focus.p-state-active {
  -moz-box-shadow: 0px 0px 5px #1f89ce;
  -webkit-box-shadow: 0px 0px 5px #1f89ce;
  box-shadow: 0px 0px 5px #1f89ce;
}
.p-togglebutton.p-button.p-state-active,
.p-selectbutton .p-button.p-state-active {
  border: 1px solid #156090;
  background: #186ba0;
  color: #ffffff;
}
.p-multiselect .p-multiselect-label {
  background-color: #ffffff;
}
.p-dropdown.p-state-focus,
.p-multiselect.p-state-focus {
  -moz-box-shadow: 0px 0px 5px #1f89ce;
  -webkit-box-shadow: 0px 0px 5px #1f89ce;
  box-shadow: 0px 0px 5px #1f89ce;
}
.p-dropdown .p-dropdown-clear-icon {
  color: #888888;
}
.p-growl-item-container.p-state-highlight.p-growl-message-info {
  background-color: #2196f3;
  border-color: #2196f3;
}
.p-growl-item-container.p-state-highlight.p-growl-message-error {
  background-color: #f44336;
  border-color: #f44336;
}
.p-growl-item-container.p-state-highlight.p-growl-message-warn {
  background-color: #ffb300;
  border-color: #ffb300;
}
.p-growl-item-container.p-state-highlight.p-growl-message-success {
  background-color: #4caf50;
  border-color: #4caf50;
}
.p-tabmenu {
  border: 0 none;
}
.p-tabmenu .p-tabmenu-nav {
  background: none;
}
.p-tabmenu .p-tabmenu-nav > li.p-state-default {
  background: #f6f7f9;
}
.p-tabmenu .p-tabmenu-nav > li.p-state-active {
  background: #ffffff;
  font-weight: normal;
  color: #555555;
}
.p-tabmenu
  .p-tabmenu-nav
  > li:not(.p-state-active):not(.p-state-disabled):hover {
  background: #ededf0;
}
.p-menu,
.p-menu .p-menu-child {
  border: 1px solid #d9d9d9;
  color: #1b1d1f;
  background: #f6f7f9 0 0 repeat-x;
  background: -moz-linear-gradient(top, #f6f7f9 0%, #ebedf0 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #f6f7f9),
    color-stop(100%, #ebedf0)
  );
  background: -webkit-linear-gradient(top, #f6f7f9 0%, #ebedf0 100%);
  background: -o-linear-gradient(top, #f6f7f9 0%, #ebedf0 100%);
  background: -ms-linear-gradient(top, #f6f7f9 0%, #ebedf0 100%);
  background: linear-gradient(to bottom, #f6f7f9 0%, #ebedf0 100%);
}
.p-menu .p-menuitem .p-menuitem-link:hover {
  background-color: #a6a6a6;
  color: #ffffff;
}
.p-menu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background-color: #a6a6a6;
  color: #ffffff;
}
.p-panelmenu .p-panelmenu-header.p-state-active,
.p-panelmenu .p-panelmenu-header.p-state-active a {
  border-color: #156090;
  background: #186ba0;
  color: #ffffff;
}
.p-datepicker.p-widget {
  padding: 0;
}
.p-datepicker.p-widget .p-datepicker-header {
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  border-top: 0 none;
  border-left: 0 none;
  border-right: 0 none;
}
.p-datepicker.p-widget .p-datepicker-header a:hover {
  border-width: 1px;
}
.p-datepicker.p-widget .p-datepicker-calendar {
  margin: 0;
}
.p-datepicker.p-widget .p-datepicker-calendar thead th {
  background-color: #f6f8fa;
  padding: 8px;
}
.p-datepicker.p-widget .p-datepicker-calendar td {
  border-bottom: 1px solid rgba(213, 213, 213, 0.5);
  padding: 0;
}
.p-datepicker.p-widget .p-datepicker-calendar td a {
  border: 0 none;
  text-align: center;
  padding: 8px;
}
.p-datepicker.p-widget .p-datepicker-calendar td a.p-state-highlight {
  background-color: #d6d6d6;
  color: #212121;
}
.p-datepicker.p-widget .p-datepicker-calendar td a.p-state-active {
  background-color: #186ba0;
  color: #ffffff;
}
.p-datepicker.p-widget .p-datepicker-calendar tr:last-child td {
  border-bottom: 0 none;
}
.p-datepicker.p-widget .p-timepicker {
  border-bottom: 0 none;
  border-left: 0 none;
  border-right: 0 none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
.p-datepicker.p-widget.p-datepicker-timeonly .p-timepicker {
  border-top: 0 none;
}
.p-steps .p-steps-item.p-state-highlight .p-menuitem-link {
  color: #ffffff;
}
.p-dialog.p-widget .p-dialog-titlebar {
  padding: 1em 1.5em;
}
.p-dialog.p-widget .p-dialog-titlebar .p-dialog-title {
  font-size: 1.25em;
}
.p-dialog.p-widget .p-dialog-content {
  padding: 1em 1.5em;
}
.fc .fc-button-group .p-state-active {
  border: 1px solid #156090;
  background: #186ba0;
  color: #ffffff;
}
.gridFilterIcon {
  cursor: pointer;
  margin-top: 5px;
  font-size: 18px;
  color: #0b64aa;
}
