.create-advanced-list {
    display: flex;
    flex-direction: column;
    height: auto!important;
    justify-content: center;
    align-items: center;
    align-content: center
}

.create-advanced-list .icon {
    height: 40px;
    width: 27px;
    color: #1da8f6;
    margin-bottom: 16px;
    margin-top: 30px
}

.create-advanced-list .icon_edit {
    width: 40px!important
}

.create-advanced-list .title {
    color: #000;
    font-family: Source Sans Pro;
    font-size: 24px;
    font-weight: 300;
    height: 31px;
    letter-spacing: 0;
    width: 390px
}

.create-advanced-list .list-name {
    color: #4a4a4a;
    font-family: Source Sans Pro;
    font-size: 16px;
    font-weight: 400;
    height: 48px;
    letter-spacing: 0;
    width: 390px
}

.create-advanced-list .list-description {
    width: 390px;
    margin-top: 0!important
}

.create-advanced-list .subtitle {
    color: #000;
    font-family: Source Sans Pro;
    font-size: 16px;
    letter-spacing: 0;
    width: 227px;
    margin-bottom: 21px;
    font-weight: 400!important
}

.create-advanced-list .separator {
    clear: both;
    width: 390px;
    float: left;
    margin: 15px 0
}

.create-advanced-list .selectModel,.create-advanced-list .selectOem {
    width: 390px
}

.create-advanced-list .assetItem {
    display: flex;
    justify-content: center;
    align-items: center
}

.create-advanced-list .assetItemContainer {
    width: 390px
}

.create-advanced-list .btnAdd {
    background: #777;
    border-radius: 0;
    border: 0;
    border-radius: 50%;
    margin-top: 35px!important;
    color: #fff
}

.create-advanced-list .list-item-title {
    color: #000;
    font-family: Source Sans Pro;
    font-size: 16px;
    font-weight: 400;
    height: 24px;
    letter-spacing: 0;
    width: 145px
}

.create-advanced-list .list-item-description {
    color: #777;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-weight: 400;
    height: 21px;
    letter-spacing: 0;
    word-wrap: break-word
}

.create-advanced-list .list-radio {
    width: 390px
}

.create-advanced-list .list-radio label {
    float: none;
    margin-top: -20px
}

.create-advanced-list .cancel {
    color: #777!important;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-weight: 700;
    height: 18px;
    letter-spacing: 0;
    text-transform: uppercase;
    width: 49px;
    cursor: pointer
}

.create-advanced-list .actions {
    display: flex;
    align-items: center;
    float: right;
    justify-content: flex-end
}

.create-advanced-list .actions .btnSave {
    margin-left: 35px
}

@media (max-width: 549px) {
    .create-advanced-list .create-advanced-list {
        overflow-x:hidden!important
    }

    .create-advanced-list .list-container {
        margin-bottom: 50px!important
    }

    .create-advanced-list .actions {
        flex-direction: column-reverse!important
    }

    .create-advanced-list .actions .or {
        color: #777;
        font-family: Source Sans Pro;
        font-size: 14px;
        font-weight: 400;
        padding: 0!important;
        letter-spacing: 0;
        text-align: center;
        text-transform: uppercase;
        width: 18px;
        padding-left: 10px
    }

    .create-advanced-list .actions {
        padding: 0 12%!important
    }

    .create-advanced-list .actions .cancel {
        margin-top: 20px
    }

    .create-advanced-list .btnSave {
        margin: 0!important;
        width: 100%
    }

    .create-advanced-list .list-radio {
        height: 70px!important
    }

    .create-advanced-list .assetItemContainer,.create-advanced-list .list-description,.create-advanced-list .list-name,.create-advanced-list .list-radio,.create-advanced-list .selectModel,.create-advanced-list .selectOem,.create-advanced-list .separator {
        width: 280px
    }

    .create-advanced-list .cancel {
        margin-bottom: 30px!important;
        margin-top: 5px!important
    }
}