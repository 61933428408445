.grid-container p-widget-header {
  background-color: white !important;
}
.grid-container .dropdown-field_select {
  padding: 5px 5px 5px 10px !important;
}
.grid-container .p-datatable thead th,
.grid-container .p-datatable tfoot td {
  text-align: left !important;
}
.grid-container .p-column-title {
  font-weight: bold;
  font-size: 18px;
}
.grid-container .p-sortable-column {
  position: absolute;
}
.grid-container .grid-button-gray {
  background-color: #f1f1f1 !important;
  color: black !important;
}
.grid-container .p-state-highlight .p-cell-data {
  font-weight: bold;
}
.grid-container .p-paginator .p-dropdown {
  float: right !important;
}
.grid-container .p-datatable tbody > tr.p-widget-content.p-state-highlight {
  color: black !important;
}
.grid-container .p-datatable .p-datatable-data > tr > td,
.grid-container .p-datatable .p-datatable-tfoot > tr > td,
.grid-container .p-datatable .p-datatable-thead > tr > th {
  padding: 10px;
}
.grid-container .p-datatable .p-datatable-thead > tr > th {
  cursor: pointer;
  padding-right: 25px;
  position: relative;
  background: #fff;
}
.grid-container .p-datatable .p-datatable-thead > tr > th:hover {
  cursor: pointer;
  padding-right: 25px;
  position: relative;
  background: #fff;
}

.grid-container .p-datatable .p-sortable-column-icon {
  position: absolute;
  right: 5px;
  margin-left:7px;
}
.grid-container .p-paginator-page,
.grid-container .p-paginator-element,
.grid-container .p-dropdown-item,
.grid-container .p-dropdown-label {
  font-family: "Source Sans Pro", sans-serif !important;
  border-style: none !important;
  padding: 5px 10px 5px 10px !important;
}
.grid-container .filter-text-lbl {
  font-weight: 500;
  font-size: 18px;
  margin-top: 40px;
}
.grid-container .p-paginator .p-dropdown {
  width: 66px !important;
}
.grid-container .custom-label-container {
  display: block;
  position: relative;
  padding-left: 0px;
  margin-top: 35px;
  font-weight: 600;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 22px;
  margin-bottom: 0;
  font-family: sans-serif;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.grid-container .p-paginator .p-dropdown-label {
  padding-left: 0 !important;
}
.grid-container .p-widget-header {
  background: white !important;
  font-weight: normal;
}
.grid-container .p-multiselect .p-multiselect-label {
  background-color: white !important;
}
.grid-container .p-datatable th.p-state-default {
  background: white !important;
}
.grid-container .p-datatable .p-column-resizer-helper {
  width: 1px;
  position: absolute;
  z-index: 10;
  display: none;
  background-color: #005da6 !important;
  height: calc(80%) !important;
}
.grid-container .p-widget-header {
  border: none !important;
}
.grid-container .preformatted {
  white-space: pre-wrap;
  position: relative;
}
.grid-container .column-formatting span:first-child,
.grid-container .column-formatting {
  font-family: "Source Sans Pro", sans-serif !important;
  height: 50px;
}

.grid-container th.column-formatting {
  border-left: none !important;
  border-right: none !important;
  border-bottom: 2px solid #c8c8c8 !important;
}

.grid-container #custom-grid table {
  min-width: 850px !important;
  width: 99% !important;
}

.grid-container .preformatted.p-column-title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap !important;
}
.grid-container .preformatted .checkMark-column {
  text-align: center;
}
.grid-container .preformatted .checkMark-column .fa-check {
  color: #ff9505;
}
.grid-container .preformatted .link {
  color: #005da6;
}
.grid-container #custom-grid {
  padding-bottom: 20px;
}
.grid-container .no-click-cell {
  pointer-events: none;
}
.grid-container .no-click-cell .link {
  color: #005da6;
  cursor: pointer;
  pointer-events: all;
}
.grid-container .p-datatable-tablewrapper {
  overflow-x: inherit !important;
}
.grid-container .p-datatable th.p-state-active {
  color: black !important;
}
.grid-container .p-paginator .p-paginator-page.p-state-active {
  background: #dcdcdc !important;
  color: black !important;
  border-color: #dcdcdc !important;
}
.grid-container .p-state-highlight {
  color: black !important;
  background: #fbcbcb !important;
}
.grid-container .p-datatable tbody > tr.p-widget-content {
  border-color: #dcdcdc !important;
}
.fa-unsorted:before,
.fa-sort:before {
  content: "" !important;
}
.grid-container .p-dropdown label.p-dropdown-label {
  background: #dcdcdc !important;
}
.grid-container .p-dropdown .p-dropdown-trigger {
  background: #dcdcdc !important;
}
.grid-container .fa-step-backward:before {
  content: "\f100" !important;
}
.grid-container .p-datatable .p-paginator-bottom {
  font-size: 20px !important;
}
.grid-container .fa-backward:before {
  content: "\f104" !important;
}
.grid-container .fa-forward:before {
  content: "\f105" !important;
}
.grid-container .fa-step-forward:before {
  content: "\f101" !important;
}
.grid-container .grid-search-box {
  float: right;
  margin-right: -30px;
  margin-top: -3px;
  width: 300px;
  background-color: #f1f1f1 !important;
}
.grid-container .text-field_title {
  height: 15px !important;
}
.grid-container
  .p-datatable
  tbody.p-datatable-hoverable-rows
  > tr.p-widget-content:not(.p-state-highlight):hover {
  background: #f1f1f1 !important;
}
.grid-container .p-paginator > .p-dropdown {
  border-style: none !important;
}
.grid-container .showGridPageLabel {
  right: 110px;
  margin-top: -30px;
  z-index: 10000;
  position: absolute;
}
.grid-container .p-chkbox-box.p-state-active,
.grid-container .p-fileupload-choose:not(.p-state-disabled):active,
.grid-container .p-radiobutton-box.p-state-active {
  border-color: #ff9505;
  background: #ff9505;
}
.grid-container .p-state-highlight .p-cell-data {
  font-weight: inherit;
}
.grid-container .p-state-highlight {
  color: inherit !important;
  background: inherit !important;
}
.grid-container .p-chkbox .p-chkbox-box {
  width: 1.525em;
  height: 1.525em;
  line-height: 1.525em;
}
.grid-container .fa {
  font-size: 18px;
}
.grid-container .isFormularyRow {
  font-weight: 600;
  background-color: rgba(255, 204, 0, 0.25) !important;
}
.grid-container .isFormularyRow:hover {
  background-color: rgba(255, 204, 0, 0.25) !important;
}
.grid-container .gridhighlightedRow {
  font-weight: 600;
  background: #fbcbcb !important;
}
.grid-container .dueShortly,
.grid-container .pastDueRow {
  font-weight: 600;
  background: #ffca82 !important;
}
.grid-container .isPast30Minutes {
  font-weight: 600;
  background-color: #ffca82 !important;
}

.grid-container .isPastDue {
  font-weight: 600;
  background-color: #ffca82 !important;
}

.grid-container .legendLabel {
  float: left !important;
}
.grid-container .legendPic {
  background: #fbcbcb;
  width: 15px;
  height: 15px;
  float: left;
  position: relative;
  margin-left: 15px;
  margin-top: 2px;
  margin-right: 5px;
}

.grid-container .pastDueLegend {
  background-color: #facbcb;
  width: 15px;
  height: 15px;
  margin-left: 15px;
  margin-top: 2px;
  margin-right: 5px;
  display: inline-block;
  margin-bottom: -2px;
}
.grid-container .pastDueLegend span {
  margin-left: 5px;
}
.grid-container .isDueLegend {
  background-color: #ffca82;
  width: 15px;
  height: 15px;
  margin-left: 15px;
  margin-top: 2px;
  margin-right: 5px;
  display: inline-block;
  margin-bottom: -2px;
}
.grid-container .isDueLegend span {
  margin-left: 5px;
}
.grid-container .grid-tools {
  position: absolute;
  width: 150px;
  background-color: white;
  right: 10px;
  top: 30px;
  z-index: 1;
  -webkit-box-shadow: -1px -1px 5px 3px #c7bfc7;
  -moz-box-shadow: -1px -1px 5px 3px #c7bfc7;
  box-shadow: -1px -1px 5px 3px #c7bfc7;
  text-align: left;
}
.grid-container .grid-tools-columns {
  right: 50px !important;
}
.grid-container .grid-tools-line {
  line-height: 40px;
  cursor: pointer;
}
.grid-container .grid-tools-line > span {
  margin-left: 10px;
}
.grid-container .grid-tools-line:hover {
  background-color: #f1f1f1;
}
.grid-container .table-header-filters {
  background-color: #f1f1f1;
  padding-bottom: 15px;
}
.grid-container .table-header-checked {
  background-color: #d0edfc;
  width: 100%;
}
.grid-container .table-blue-grid {
  padding-top: 10px;
  padding-bottom: 10px;
}
.grid-container .table-header-close {
  font-size: 24px;
  margin-right: 5px;
  float: right;
  cursor: pointer;
}
.grid-container .items-lbl {
  font-size: 18px;
  font-weight: bold;
}
.grid-container .undo-lbl {
  font-size: 18px;
  cursor: pointer;
  text-decoration: underline;
}
.grid-container .table-header-collapse {
  font-size: 24px;
  margin-top: 30px;
  margin-right: 5px;
  float: right;
  cursor: pointer;
}
.grid-container .add-filter {
  background-color: white !important;
  color: #949494 !important;
  width: 140px;
  margin-top: -7px;
}
.grid-container .apply-filter {
  background-color: #f1f1f1 !important;
  color: #949494 !important;
  width: 140px;
  margin-top: -7px;
}
.grid-container .nopadding {
  padding: 0px;
}
.grid-container .nomargin {
  margin: 0px;
}
