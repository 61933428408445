.admin-settings h1 {
    margin: 20px 20px 40px
}

.admin-settings .settings-section {
    margin-left: 40px;
    padding-left: 40px;
    line-height: 24px;
    margin-bottom: 30px
}

.admin-settings .settings-section .fa,.admin-settings .settings-section .settings-section-icon {
    float: left;
    margin-left: -40px;
    font-size: 24px
}

.admin-settings .settings-section .section-name {
    font-size: 18px;
    font-weight: 600;
    color: #005da6;
    cursor: pointer
}

.admin-settings .settings-section .section-name-disabled {
    font-size: 18px;
    font-weight: 600;
    color: #404041
}

.admin-settings .settings-section .section-description {
    font-size: 16px
}

.admin-settings .settings-section .section-link {
    font-weight: 600;
    cursor: pointer;
    color: #005da6
}

.admin-settings .settings-section .fa-arrows-h,.admin-settings .settings-section .fa-file-invoice-dollar,.admin-settings .settings-section .fa-long-arrow-left,.admin-settings .settings-section .fa-screwdriver-wrench {
    float: left;
    margin-left: -40px;
    font-size: 24px
}
.field {
    margin-top: 20px
}

.print-wrapper {
  margin: auto;
}

.print-wrapper-content {
  display: inline-block;
  width: 50%;
}

.print-wrapper-content-header {
  display: none;
}

.print-wrapper-content-header-main {
  display: block;
}

.widget-container {
  display: flex;
  flex-wrap: wrap;    
  justify-content: space-between;
  margin-bottom: 24px;
}

.widget-item {
  flex-basis: calc(33.333%);
  margin-bottom: 24px;
}

@media (max-width: 1200px) {
  .widget-item {
    flex-basis: calc(50% - 12px);
  }

  #widget-powerbi-container {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .widget-item {
    flex-basis: 100%;
  }
}

#sisense-container {
  cursor: pointer;
  width: calc(50% - 2em);
  margin: 1em;
  max-width: 750px;
  background: rgb(255, 255, 255);
  border-radius: 3px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
}

@media (max-width: 819px) {
  #sisense-container {
    width: calc(100%);
  }
}

#sisense-container-print {
  width: 100%; 
  position: relative;
  display: inline-block;
}

#widget-powerbi-container {
  cursor: pointer;
  width: calc(100% - 2em);
  margin: 1em;
  max-width: 800px;
  background: rgb(255, 255, 255);
  border-radius: 3px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
}

@media (max-width: 819px) {
  #widget-powerbi-container {
    width: calc(100%);
  }
}

#powerbi-container-print {
  width: 100%; 
  position: relative;
  display: inline-block;
}

.widget-power-bi-report {
  height: 400px;

  iframe {
    border: 0;
   }
  }

@media only screen and (max-width: 1780px) {
  .widget-power-bi-report {
    height: 250px;
  }
}

@media only screen and (max-width: 1589px) {
  .widget-power-bi-report {
    height: 200px;
  }
}

@media only screen and (max-width: 1345px) {
  .widget-power-bi-report {
    height: 230px;
  }
}

@media only screen and (max-width: 1080px) {
  .widget-power-bi-report {
    height: 250px;
  }
}

@media only screen and (max-width: 819px) {
  .widget-power-bi-report {
    height: 340px;
  }
}

@media only screen and (max-width: 710px) {
  .widget-power-bi-report {
    height: 300px;
  }
}

@media only screen and (max-width: 597px) {
  .widget-power-bi-report {
    height: 300px;
  }
}

@media only screen and (max-width: 509px) {
  .widget-power-bi-report {
    height: 250px;
  }
}

@media only screen and (max-width: 430px) {
  .widget-power-bi-report {
    height: 180px;
  }
}

@media only screen and (max-width: 375px) {
  .widget-power-bi-report {
    height: 155px;
  }
}

@media only screen and (max-width: 360px) {
  .widget-power-bi-report {
    height: 145px;
  }
}

.widget-overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.sortableHelper {
  z-index: 9999;
  background-color: lightgrey;
  padding: 10px 10px 10px 15px;
}

.sortableHelper .fa-reorder {
  display: inline-block;
  padding-right: 10px;
}

.middle-view-filter-label {
  margin-left: auto;
  margin-right: 10px;
  display: none;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  cursor: pointer;
  color: rgb(119, 119, 119);
}

.mobile-filter-dialog > div > div:nth-child(2) {
  flex: 1;
}

.mobile-filter-dialog > div > div:nth-child(2) > div:nth-child(2) {
  margin-top: 25px;
  border-top: 1px solid #cccccc;
}

@media (max-width: 549px) {
  .mobile-filter-dialog > div > div:nth-child(3) > button {
    width: 100%;
  }
}

@media (max-width: 820px) {
  .middle-view-filter-label {
    display: inline-block !important;
  }
}

.dashboard-header-bar {
  vertical-align: top;
  width: 100%;
  padding-top: 35px;
  height: 87px;
  margin-right: 20px;
}

.header-filters {
  width: auto;
  margin-right: 20px;
}

.header-filters .material-icons {
  font-family: Material Icons;
  font-size: 14px;
}

@media (max-width: 820px) {
  .header-filters {
    display: none !important;
  }
}

@media (max-width: 820px) {
  .header-filters-clear {
    display: none !important;
  }
}

.header-filters-date {
  margin-right: 10px;
}

@media (min-width: 820px) {
  .header-filters-date {
    float: right;
  }
}

@media print {
  @page {
    size: landscape;
  }
  
  #sisense-container-print {
    width: 100%; 
    max-width: 750px;
    height: 550px; 
    display: inline-block;
  }

  .print-wrapper {
    width: 100%;
    margin: auto;
  }

  .print-wrapper-content-header {
    display: block;
  }
  
  .print-wrapper-content-header-main {
    display: none;
  }

  .print-wrapper-content {
    display: inline-block;
    width: 100%;
  }
}