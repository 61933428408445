.power-bi-single-report {
  height: 720px;
}

.multi-grid-page {
  background: unset !important;
}

.embedContainer-container {
  width: 100%;
}

.loading-reports .loader {
  left: calc(50% - 37.5px) !important;
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid #FF9505; /* Replace #FF9505 with your @orange1 color */
  width: 75px;
  height: 75px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  position: absolute;
  top: 150px;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}